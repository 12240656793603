import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Stack } from '@atlaskit/primitives';
import Select from '@atlaskit/select';
import { ErrorMessage, Label } from '@atlaskit/form';
import { IconButton } from '@atlaskit/button/new';
import DeleteIconMigration from '@atlaskit/icon/core/delete';

import { fg } from '@confluence/feature-gating';

import { messages } from './messages';
import type { Column, UnsavedColumnToFieldMapping } from './providers/BulkConfigureContextProvider';
import { useMappingsActions } from './providers/BulkConfigureContextProvider';
import type { FieldMetadata } from './__types__/apiUtils';

interface Option {
	label: string;
	value: Column | FieldMetadata;
}

export interface ColumnToIssueFieldMappingProps {
	index: number;
	mapping: UnsavedColumnToFieldMapping;
	tableColumnOptions: Option[];
	issueFieldOptions: Option[];
	onMappingChange: (index: number, newMapping: UnsavedColumnToFieldMapping) => void;
	isLoading: boolean;
	isDisabled: boolean;
}

export const TABLE_COLUMN_SELECT_ID = 'table-column-select';
export const ISSUE_FIELD_SELECT_ID = 'issue-field-select';

export const ColumnToIssueFieldMapping = ({
	index,
	mapping,
	tableColumnOptions,
	issueFieldOptions,
	onMappingChange,
	isLoading,
	isDisabled,
}: ColumnToIssueFieldMappingProps) => {
	const { deleteUnsavedColumnToFieldMapping } = useMappingsActions();
	const handleSelectChange = useCallback(
		(idx: number, key: 'column' | 'field', newValue: any) => {
			if (newValue) {
				onMappingChange(idx, { ...mapping, [key]: newValue.value });
			}
		},
		[mapping, onMappingChange],
	);

	const deleteMapping = useCallback(
		(idx: number) => {
			if (isLoading) {
				return;
			}
			deleteUnsavedColumnToFieldMapping(idx);
		},
		[isLoading, deleteUnsavedColumnToFieldMapping],
	);

	return (
		<Stack space="space.100">
			<Stack>
				<Label htmlFor="table-column-select">
					<FormattedMessage {...messages.bulkConfigureMapTableToIssueTableColumn} />
				</Label>
				<Select
					inputId={TABLE_COLUMN_SELECT_ID}
					classNamePrefix={TABLE_COLUMN_SELECT_ID}
					appearance="default"
					options={tableColumnOptions}
					defaultValue={tableColumnOptions.find((option) => option.value === mapping.column)}
					onChange={(newValue) => handleSelectChange(index, 'column', newValue)}
					isLoading={isLoading}
					isDisabled={isDisabled}
				/>
				{mapping.errors.column && <ErrorMessage> {mapping.errors.column.message} </ErrorMessage>}
			</Stack>
			<Stack>
				<Label htmlFor="issue-field-select">
					{fg('confluence-issue-terminology-refresh') ? (
						<FormattedMessage
							{...messages.bulkConfigureMapTableToIssueIssueFieldIssueTermRefresh}
						/>
					) : (
						<FormattedMessage {...messages.bulkConfigureMapTableToIssueIssueField} />
					)}
				</Label>
				<Select
					inputId={ISSUE_FIELD_SELECT_ID}
					classNamePrefix={ISSUE_FIELD_SELECT_ID}
					appearance="default"
					options={issueFieldOptions}
					defaultValue={issueFieldOptions.find((option) => option.value === mapping.field)}
					onChange={(newValue) => handleSelectChange(index, 'field', newValue)}
					isLoading={isLoading}
					isDisabled={isDisabled || mapping.isDefaultMapping}
				/>
				{mapping.errors.field && <ErrorMessage> {mapping.errors.field.message} </ErrorMessage>}
			</Stack>
			{!mapping.isDefaultMapping && fg('confluence_bulk_issue_create_additional_mappings') && (
				<Stack alignInline="end">
					<IconButton
						appearance="subtle"
						icon={DeleteIconMigration}
						onClick={() => deleteMapping(index)}
						isDisabled={isDisabled}
						label=""
						testId="delete-additional-mapping"
					/>
				</Stack>
			)}
		</Stack>
	);
};
