import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';

type ActionType = 'createAll' | 'createSingleIssue';

export const HIGHLIGHT_ISSUE_CREATE_SOURCE = 'confluenceHighlightIssueCreate';
export const BULK_ISSUE_CREATE_SOURCE = 'confluenceBulkIssueCreate';
export const AI_BULK_ISSUE_CREATE_FEATURE_NAME = 'aiIssueCreateConfluenceBulk';

const buildAttributes = (
	baseAttributes: Record<string, any>,
	isCreatedWithAi: boolean,
	isCreatedFromTable: boolean,
) => {
	baseAttributes.isCreatedFromTable = isCreatedFromTable;
	baseAttributes.isCreatedWithAi = isCreatedWithAi;
	if (isCreatedWithAi) {
		return { ...baseAttributes, aiFeatureName: AI_BULK_ISSUE_CREATE_FEATURE_NAME };
	}
	return baseAttributes;
};

export const getSource = (isCreatedFromTable: boolean) => {
	return isCreatedFromTable ? HIGHLIGHT_ISSUE_CREATE_SOURCE : BULK_ISSUE_CREATE_SOURCE;
};

const getErrorMessage = (error: Error | unknown) => {
	if (error instanceof Error) return error.message;
	return String(error);
};

export const useBulkCreateLoggingEvents = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const {
		trackAIResultError,
		trackAIInteractionInit,
		trackAIResultView,
		trackAIResultAction,
		trackAIInteractionDismiss,
	} = useAIEventsInstrumentation();
	const [{ isCreatedWithAi, isCreatedFromTable }] = useBulkCreateContext();

	const logMultipleIssueCreateSuccess = useCallback(
		(numIssues: number) => {
			const attributes = buildAttributes({ numIssues }, isCreatedWithAi, isCreatedFromTable);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: getSource(isCreatedFromTable),
					action: 'created',
					actionSubject: 'issue',
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent, isCreatedWithAi, isCreatedFromTable],
	);

	const logMultipleIssueCreateFailure = useCallback(
		(numIssues: number, error: Error | unknown) => {
			const attributes = buildAttributes(
				{ error: getErrorMessage(error), numIssues },
				isCreatedWithAi,
				isCreatedFromTable,
			);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: getSource(isCreatedFromTable),
					action: 'failed',
					actionSubject: 'issueCreation',
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent, isCreatedWithAi, isCreatedFromTable],
	);

	const logSingleIssueCreateSuccess = useCallback(() => {
		logMultipleIssueCreateSuccess(1);
	}, [logMultipleIssueCreateSuccess]);

	const logSingleIssueCreateFailure = useCallback(
		(error: Error | unknown) => {
			const attributes = buildAttributes(
				{ error: getErrorMessage(error) },
				isCreatedWithAi,
				isCreatedFromTable,
			);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: getSource(isCreatedFromTable),
					action: 'error',
					actionSubject: 'globalIssueCreate',
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent, isCreatedWithAi, isCreatedFromTable],
	);

	const logCancelAiPanel = useCallback(() => {
		const attributes = buildAttributes({}, isCreatedWithAi, isCreatedFromTable);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: getSource(isCreatedFromTable),
				action: 'cancelled',
				actionSubject: 'aiPanel',
				attributes,
			},
		}).fire();
	}, [createAnalyticsEvent, isCreatedWithAi, isCreatedFromTable]);

	const logAiResultViewed = useCallback(
		(issuesGenerated: number) => {
			const attributes = buildAttributes({ issuesGenerated }, isCreatedWithAi, isCreatedFromTable);
			trackAIResultView({ attributes });
		},
		[trackAIResultView, isCreatedWithAi, isCreatedFromTable],
	);

	const logAiResultActioned = useCallback(
		(aiResultAction: ActionType, issueId?: string) => {
			const attributes = buildAttributes({}, isCreatedWithAi, isCreatedFromTable);
			trackAIResultAction(aiResultAction, { attributes: { ...attributes, issueId } });
		},
		[isCreatedFromTable, isCreatedWithAi, trackAIResultAction],
	);

	const logAiResultRemoved = useCallback(() => {
		const attributes = buildAttributes({}, isCreatedWithAi, isCreatedFromTable);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: getSource(isCreatedFromTable),
				action: 'removed',
				actionSubject: 'aiResult',
				attributes,
			},
		}).fire();
	}, [createAnalyticsEvent, isCreatedFromTable, isCreatedWithAi]);

	const logRequestFailed = useCallback(
		({ requestName, errorMessage }: { requestName: string; errorMessage: string }) => {
			const attributes = buildAttributes({ errorMessage }, isCreatedWithAi, isCreatedFromTable);
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					source: getSource(isCreatedFromTable),
					action: 'failed',
					actionSubject: requestName,
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent, isCreatedFromTable, isCreatedWithAi],
	);

	const logSidePanelIssueRemoved = useCallback(() => {
		if (isCreatedWithAi) {
			logAiResultRemoved();
		}
		const attributes = buildAttributes({}, isCreatedWithAi, isCreatedFromTable);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: getSource(isCreatedFromTable),
				action: 'removed',
				actionSubject: 'sidePanelIssue',
				attributes,
			},
		}).fire();
	}, [isCreatedWithAi, isCreatedFromTable, createAnalyticsEvent, logAiResultRemoved]);

	const logSidePanelIssueApproved = useCallback(
		(issueAction: ActionType) => {
			const attributes = buildAttributes({ issueAction }, isCreatedWithAi, isCreatedFromTable);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: getSource(isCreatedFromTable),
					action: 'approved',
					actionSubject: 'sidePanelIssue',
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent, isCreatedWithAi, isCreatedFromTable],
	);

	const logCreateAllClick = useCallback(() => {
		if (isCreatedWithAi) {
			logAiResultActioned('createAll');
		}
		logSidePanelIssueApproved('createAll');
	}, [isCreatedWithAi, logAiResultActioned, logSidePanelIssueApproved]);

	const logCreateSingleIssueClick = useCallback(
		(issueId?: string) => {
			if (isCreatedWithAi) {
				logAiResultActioned('createSingleIssue', issueId);
			}
			logSidePanelIssueApproved('createSingleIssue');
		},
		[isCreatedWithAi, logAiResultActioned, logSidePanelIssueApproved],
	);

	const logAiIssueCreationFailed = useCallback(
		(numIssues: number, error: Error | unknown = '') => {
			const attributes = buildAttributes(
				{
					errorMessage: getErrorMessage(error),
					numIssues,
				},
				isCreatedWithAi,
				isCreatedFromTable,
			);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: getSource(isCreatedFromTable),
					action: 'failed',
					actionSubject: 'aiIssueCreation',
					attributes,
				},
			}).fire();
		},
		[createAnalyticsEvent, isCreatedWithAi, isCreatedFromTable],
	);

	const logAiInteractionDismissed = useCallback(() => {
		const attributes = buildAttributes({}, isCreatedWithAi, isCreatedFromTable);
		trackAIInteractionDismiss({ attributes });
	}, [isCreatedFromTable, isCreatedWithAi, trackAIInteractionDismiss]);

	const logAiNoResultsGenerated = useCallback(() => {
		const attributes = buildAttributes({}, isCreatedWithAi, isCreatedFromTable);
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				source: getSource(isCreatedFromTable),
				action: 'generated',
				actionSubject: 'aiNoResults',
				attributes,
			},
		}).fire();
	}, [createAnalyticsEvent, isCreatedFromTable, isCreatedWithAi]);

	const logAiInteractionInitiated = useCallback(() => {
		const attributes = buildAttributes({}, isCreatedWithAi, isCreatedFromTable);
		trackAIInteractionInit({ attributes });
	}, [isCreatedFromTable, isCreatedWithAi, trackAIInteractionInit]);

	const logAiStreamingBackendFailed = useCallback(
		(error: Error | unknown = '') => {
			const attributes = buildAttributes({}, isCreatedWithAi, isCreatedFromTable);
			trackAIResultError({ aiErrorMessage: getErrorMessage(error) }, { attributes });
		},
		[isCreatedFromTable, isCreatedWithAi, trackAIResultError],
	);

	const logIssueCreationFailed = useCallback(
		(numIssues: number, error: Error | unknown = '') => {
			if (isCreatedWithAi) {
				logAiIssueCreationFailed(numIssues, error);
			}
			logMultipleIssueCreateFailure(numIssues, error);
		},
		[isCreatedWithAi, logAiIssueCreationFailed, logMultipleIssueCreateFailure],
	);

	const logSidePanelClosed = useCallback(() => {
		const attributes = buildAttributes({}, isCreatedWithAi, isCreatedFromTable);
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: getSource(isCreatedFromTable),
				action: 'closed',
				actionSubject: 'modalDialog',
				actionSubjectId: 'linkCreate',
				attributes,
			},
		}).fire();
	}, [createAnalyticsEvent, isCreatedWithAi, isCreatedFromTable]);

	return {
		logSingleIssueCreateSuccess,
		logMultipleIssueCreateSuccess,
		logSingleIssueCreateFailure,
		logCancelAiPanel,
		logCreateAllClick,
		logCreateSingleIssueClick,
		logAiInteractionInitiated,
		logAiResultViewed,
		logAiResultRemoved,
		logAiInteractionDismissed,
		logAiNoResultsGenerated,
		logRequestFailed,
		logAiStreamingBackendFailed,
		logSidePanelIssueRemoved,
		logSidePanelIssueApproved,
		logIssueCreationFailed,
		logSidePanelClosed,
	};
};
