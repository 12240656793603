import { useEffect } from 'react';

import type { Observable } from 'rxjs/Observable';

import { useStatefulObservableLazyEffect } from './useStatefulObservableLazyEffect';

export type ObservableStatefulInput<TInputs extends Readonly<any[]>, TState> = {
	inputs: [...TInputs];
	state: TState;
};

export function useStatefulObservableEffect<TState, TInputs extends Readonly<any[]>>(
	onObservableInit: (
		input$: Observable<ObservableStatefulInput<TInputs, TState>>,
	) => Observable<TState>,
	initialState: TState,
	inputs: [...TInputs],
): [TState, (nextState: ObservableStatefulInput<TInputs, TState>) => void] {
	const [state, callback] = useStatefulObservableLazyEffect(onObservableInit, initialState);

	useEffect(() => {
		callback({ inputs, state: initialState });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, inputs);

	return [state, callback];
}
