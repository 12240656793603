/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React from 'react';
import { css, jsx, keyframes } from '@compiled/react';

type RainbowLoadingSidebarProps = {
	children: React.ReactNode;
	isShown: boolean;
};

const defaultStyles = css({
	position: 'absolute',
	width: '100%',
	backgroundColor: 'elevation.surface',
	height: '100%',
	top: '0',
	right: '0',
	left: '0',
	bottom: '0',
	display: 'flex',
	flexDirection: 'column',
});

const animateBorder = keyframes({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	// @ts-ignore - angle is a custom property, so it's not recognized by the TS compiler
	from: {
		'--angle': '0deg',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
	to: {
		'--angle': '360deg',
	},
});

const gradientBarStyles = css({
	'--angle': '0deg',
	borderLeft: '2px solid',
	borderImage: 'conic-gradient(from var(--angle), #0065FF, #0469FF, #BF63F3, #FFA900, #0065FF) 1',
	animation: `1s ${animateBorder} linear infinite`,
});

const addAngleStyles = css({
	'@property --angle': {
		syntax: "'<angle>'",
		initialValue: '0deg',
		inherits: 'false',
	},
});

export const RainbowLoadingSidebar = ({ children, isShown }: RainbowLoadingSidebarProps) => {
	return (
		<div
			data-testId="rainbow-loading-sidebar"
			css={[defaultStyles, isShown && gradientBarStyles, isShown && addAngleStyles]}
		>
			{children}
		</div>
	);
};
