import React from 'react';

import ThumbsUpIcon from '@atlaskit/icon/core/thumbs-up';
import { token } from '@atlaskit/tokens';

export const ThumbsIcon = ({ isSelected }: { isSelected: boolean }) => (
	<ThumbsUpIcon
		color={isSelected ? token('color.icon.selected') : token('color.icon.subtle')}
		spacing="spacious"
		label=""
	/>
);
