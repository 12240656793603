import type {
	FollowUpObject,
	StreamError,
	TokenUsageData,
} from '../api/AssistantService/StreamMessage';

export enum AIAgentResponseState {
	Initialization = 'Initialization',
	AnswerPart = 'AnswerPart',
	FinalResponse = 'FinalResponse',
	FollowUp = 'FollowUp',
	Error = 'Error',
}

type MetaData = {
	tokenData?: TokenUsageData;
};

export type AIAgentStreamingState<TContent = string> =
	| {
			responseState: AIAgentResponseState.Initialization;
			content: TContent | null;
			answerChunkIndex?: number;
			error?: StreamError;
			followUpContent?: FollowUpObject[];
			metadata?: MetaData;
	  }
	| {
			responseState: AIAgentResponseState.AnswerPart;
			content: TContent;
			answerChunkIndex: number;
			error?: StreamError;
			followUpContent?: FollowUpObject[];
			metadata?: MetaData;
	  }
	| {
			responseState: AIAgentResponseState.FinalResponse;
			content: TContent;
			answerChunkIndex?: number;
			error?: StreamError;
			followUpContent?: FollowUpObject[];
			metadata: MetaData;
	  }
	| {
			responseState: AIAgentResponseState.FollowUp;
			content: TContent | null;
			answerChunkIndex?: number;
			error?: StreamError;
			followUpContent: FollowUpObject[];
			metadata?: MetaData;
	  }
	| {
			responseState: AIAgentResponseState.Error;
			content: TContent | null;
			answerChunkIndex?: number;
			error: StreamError;
			followUpContent?: FollowUpObject[];
			metadata?: MetaData;
	  };

export function getDefaultAIAgentStreamingState<
	TAIAgentStreamingRequest,
>(): AIAgentStreamingState<TAIAgentStreamingRequest> {
	return {
		content: null,
		responseState: AIAgentResponseState.Initialization,
	};
}
