import React, { useCallback } from 'react';

import { Inline } from '@atlaskit/primitives';
import CheckIcon from '@atlaskit/icon/core/check-mark';
import CrossIcon from '@atlaskit/icon/core/close';
import EditFilledIcon from '@atlaskit/icon/core/edit';
import { IconButton } from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';

import { useBulkCreateContext, useSetCurrentIssueId } from './providers/BulkCreateContextProvider';
import { useCreateIssue } from './utils/useCreateIssue';
import { useBulkCreateFlags } from './utils/useBulkCreateFlags';
import { useSetErrorMessage } from './providers/AiBulkNavigationContextProvider';
import { useBulkCreateLoggingEvents } from './utils/useBulkCreateLoggingEvents';

type BulkCreateIssueCardToolBarProps = {
	issueId: string;
};

export const BulkCreateIssueCardToolBar = ({ issueId }: BulkCreateIssueCardToolBarProps) => {
	const setCurrentIssueId = useSetCurrentIssueId();

	const [{ issueTypeHasRequiredFields }, { removeBulkIssue }] = useBulkCreateContext();
	const { createIssueHandler, loading: isCreateLoading } = useCreateIssue();
	const { triggerSingleIssueCreatedFlag } = useBulkCreateFlags();
	const setErrorMessage = useSetErrorMessage();
	const {
		logCreateSingleIssueClick,
		logSingleIssueCreateSuccess,
		logIssueCreationFailed,
		logSidePanelIssueRemoved,
	} = useBulkCreateLoggingEvents();

	const isCreateDisabled = issueTypeHasRequiredFields || isCreateLoading;

	const onAcceptClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.stopPropagation();
			void setErrorMessage(null);

			let createdIssueId: string | undefined;
			void createIssueHandler({
				issueId,
				onComplete: ({ key: issueKey, summary, id }) => {
					logSingleIssueCreateSuccess();
					void removeBulkIssue(issueId);
					createdIssueId = id;
					triggerSingleIssueCreatedFlag({ issueKey, summary });
				},
				onFailure: (error: Error | unknown) => {
					logIssueCreationFailed(1, error);
					void setErrorMessage({ createdCount: 0, failedCount: 1 });
				},
			});
			logCreateSingleIssueClick(createdIssueId);
		},
		[
			issueId,
			removeBulkIssue,
			createIssueHandler,
			triggerSingleIssueCreatedFlag,
			setErrorMessage,
			logCreateSingleIssueClick,
			logSingleIssueCreateSuccess,
			logIssueCreationFailed,
		],
	);

	const onDiscardClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.stopPropagation();
			logSidePanelIssueRemoved();
			void setErrorMessage(null);
			void removeBulkIssue(issueId);
		},
		[issueId, removeBulkIssue, setErrorMessage, logSidePanelIssueRemoved],
	);

	const onEditClick = useCallback(() => {
		void setErrorMessage(null);
		void setCurrentIssueId(issueId);
	}, [issueId, setCurrentIssueId, setErrorMessage]);

	return (
		<Inline alignBlock="center" space="space.050">
			<IconButton
				appearance="subtle"
				icon={(iconProps) => (
					<CheckIcon {...iconProps} color={token('color.icon')} spacing="none" />
				)}
				isDisabled={isCreateDisabled}
				label="Accept"
				onClick={onAcceptClick}
				testId="bulk-create-issue-card-accept-action"
			/>
			<IconButton
				appearance="subtle"
				icon={(iconProps) => (
					<CrossIcon {...iconProps} color={token('color.icon')} spacing="none" />
				)}
				isDisabled={isCreateLoading}
				label="Discard"
				onClick={onDiscardClick}
				testId="bulk-create-issue-card-discard-action"
			/>
			<IconButton
				appearance="subtle"
				icon={(iconProps) => (
					<EditFilledIcon {...iconProps} color={token('color.icon')} spacing="none" />
				)}
				isDisabled={isCreateLoading}
				label="Edit"
				onClick={onEditClick}
				testId="bulk-create-issue-card-edit-action"
			/>
		</Inline>
	);
};
