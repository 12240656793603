import React from 'react';

import { Stack, Inline, xcss } from '@atlaskit/primitives';

import { usePageContentId } from '@confluence/page-context';

import { AiSubFooter } from './AiSubFooter';
import { BulkCreateButtonGroup } from './BulkCreateButtonGroup';
import { IssueCreateAiFeedback } from './IssueCreateAiFeedback';

const footerBaseStyles = xcss({
	backgroundColor: 'elevation.surface',
	flexShrink: 0,
	position: 'relative',
	'::before': {
		content: '""',
		position: 'absolute',
		top: 'space.0',
		left: 'space.150',
		right: 'space.150',
		height: '1px',
		backgroundColor: 'elevation.surface',
		borderTopColor: 'color.border',
		borderTopStyle: 'solid',
		borderTopWidth: 'border.width',
	},
});

const footerTopStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
});

const AI_BULK_CREATE_FEEDBACK_ENTRYPOINT_ID = 'bfca508d-e2bb-4436-a060-3fa27bdce78f';

export const BulkCreateFooter = ({
	onClose,
	onCreateAll,
}: {
	onClose: () => void;
	onCreateAll: () => void;
}) => {
	const [contentId] = usePageContentId();
	return (
		<Stack xcss={footerBaseStyles}>
			<Inline alignInline="center" grow="fill" spread="space-between" xcss={footerTopStyles}>
				<IssueCreateAiFeedback
					contentId={contentId ?? ''}
					entrypointId={AI_BULK_CREATE_FEEDBACK_ENTRYPOINT_ID}
				/>
				<BulkCreateButtonGroup onClose={onClose} onCreateAll={onCreateAll} />
			</Inline>
			<AiSubFooter />
		</Stack>
	);
};
