import gql from 'graphql-tag';

export const BulkIssueADFQuery = gql`
	query BulkIssueADFQuery($contentId: ID!) {
		singleContent(id: $contentId) {
			id
			body {
				atlas_doc_format {
					value
				}
			}
		}
	}
`;
