import { useEffect, useState } from 'react';

import { useSetIssueTypeFields } from '../providers/BulkCreateContextProvider';
import {
	useAvailableSitesState,
	useIssueTypesState,
	useMappingsActions,
	useProjectsState,
} from '../providers/BulkConfigureContextProvider';

import { fetchIssuesMetadata } from './apiUtils';

export const useGetIssueTypeFields = () => {
	const setIssueTypeFields = useSetIssueTypeFields();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const { selectedAvailableSite } = useAvailableSitesState();
	const { selectedProject } = useProjectsState();
	const { selectedIssueType } = useIssueTypesState();
	const { setOptionsIssueTypeFields, setLoadingIssueTypeFields } = useMappingsActions();

	useEffect(() => {
		const fetchIssueMetadata = async () => {
			if (!selectedAvailableSite || !selectedProject || !selectedIssueType) {
				return;
			}

			setIsLoading(true);
			setLoadingIssueTypeFields(true);

			try {
				const data = await fetchIssuesMetadata({
					cloudId: selectedAvailableSite.cloudId,
					projectId: selectedProject.id,
					issueTypeId: selectedIssueType.id,
				});
				if (data) {
					const requiredFields = data.fields.filter((field) => field.required);
					const optionalFields = data.fields.filter((field) => !field.required);
					void setIssueTypeFields({ requiredFields, optionalFields });
					void setOptionsIssueTypeFields([...requiredFields, ...optionalFields]);
				}
			} catch (e: Error | unknown) {
				if (isError(e)) {
					setError(e.message);
				}
			} finally {
				setIsLoading(false);
				setLoadingIssueTypeFields(false);
			}
		};
		void fetchIssueMetadata();
	}, [
		selectedAvailableSite,
		selectedProject,
		selectedIssueType,
		setIssueTypeFields,
		setOptionsIssueTypeFields,
		setLoadingIssueTypeFields,
	]);

	return { isLoading, error };
};

const isError = (error: Error | unknown): error is Error => {
	return error instanceof Error;
};
