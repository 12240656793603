import { useEffect, useState } from 'react';

import {
	useBulkCreateContext,
	useSetIssueTypeHasRequiredFields,
} from '../providers/BulkCreateContextProvider';

import { fetchIssuesMetadata } from './apiUtils';

export const defaultRequiredFields = ['description', 'issuetype', 'project', 'summary'];

export const useHasRequiredFields = () => {
	const setIssueTypeHasRequiredFields = useSetIssueTypeHasRequiredFields();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [{ currentJiraSite, currentJiraProject, currentDefaultIssueType }] = useBulkCreateContext();

	useEffect(() => {
		const fetchIssueMetadata = async () => {
			if (!currentJiraProject || !currentDefaultIssueType || !currentJiraSite) {
				return;
			}

			setIsLoading(true);

			try {
				const data = await fetchIssuesMetadata({
					cloudId: currentJiraSite.cloudId,
					projectId: currentJiraProject.id,
					issueTypeId: currentDefaultIssueType.id,
				});
				if (data) {
					const requiredFieldFound = data.fields.some(
						(field) =>
							field.required &&
							!defaultRequiredFields.includes(field.key) &&
							!field.hasDefaultValue,
					);
					void setIssueTypeHasRequiredFields(requiredFieldFound);
				}
			} catch (e: Error | unknown) {
				if (isError(e)) {
					setError(e.message);
				}
			} finally {
				setIsLoading(false);
			}
		};
		void fetchIssueMetadata();
	}, [currentJiraSite, currentJiraProject, currentDefaultIssueType, setIssueTypeHasRequiredFields]);

	return { isLoading, error };
};

const isError = (error: Error | unknown): error is Error => {
	return error instanceof Error;
};
