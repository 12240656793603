import React, { type FC } from 'react';

import { Box } from '@atlaskit/primitives';
import { FlagsProvider } from '@atlaskit/flag';

import {
	SmartsFeedbackComponent,
	FeedbackCollectorPropertiesProvider,
} from '@confluence/quick-summary';

import { useIssueCreateSidePanelContext } from './providers/IssueCreateSidePanelContextProvider';

const SOURCE = 'confluenceHighlightIssueCreate';

type IssueCreateAiFeedbackProps = {
	contentId: string;
	entrypointId: string;
};

export const IssueCreateAiFeedback: FC<IssueCreateAiFeedbackProps> = ({
	contentId,
	entrypointId,
}) => {
	const [{ aiState, aiAnalyticsContext }] = useIssueCreateSidePanelContext();
	const shouldShowFeedbackCollector = aiState !== 'loading';
	const additionalAnalyticsContext = { additionalAnalyticsAttributes: aiAnalyticsContext };
	return shouldShowFeedbackCollector ? (
		<FlagsProvider>
			<FeedbackCollectorPropertiesProvider entrypointId={entrypointId}>
				<Box padding="space.100">
					<SmartsFeedbackComponent
						analyticsProperties={{
							source: SOURCE,
							...additionalAnalyticsContext,
						}}
						contentId={contentId}
					/>
				</Box>
			</FeedbackCollectorPropertiesProvider>
		</FlagsProvider>
	) : null;
};
