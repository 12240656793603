import { useCallback, useRef } from 'react';

import uuid from 'uuid/v4';

import { AISessionState } from '../events';

export const useAIInstrumentationSetup = (singleInstrumentationID: string | undefined) => {
	const singleInstrumentationIDRef = useRef<string | undefined>(undefined);
	const aiSessionStateRef = useRef(AISessionState.unset);

	const setAISessionState = useCallback((nextState: AISessionState) => {
		aiSessionStateRef.current = nextState;
	}, []);

	const getAISessionState = useCallback(() => {
		return aiSessionStateRef.current;
	}, []);

	const refreshSingleInstrumentationID = useCallback(
		(customSingleInstrumentationID?: string) => {
			// priority for setting singleInstrumentationID override rule
			// 1. from consumer event attribute
			// 2. from consumer configuration
			// 3. default behaviour generates through uuid lib
			singleInstrumentationIDRef.current =
				customSingleInstrumentationID ?? singleInstrumentationID ?? uuid();
		},
		[singleInstrumentationID],
	);

	const getSingleInstrumentationID = useCallback(() => {
		// this handles a race condition when we have a new singleInstrumentationID from the user config
		// but Provider knows only about the initial singleInstrumentationID. In this case we need to update
		// singleInstrumentationID at first step and then return a new one.
		if (singleInstrumentationID && singleInstrumentationID !== singleInstrumentationIDRef.current) {
			refreshSingleInstrumentationID();
		}
		return singleInstrumentationIDRef.current;
	}, [refreshSingleInstrumentationID, singleInstrumentationID]);

	return {
		refreshSingleInstrumentationID,
		getSingleInstrumentationID,
		setAISessionState,
		getAISessionState,
	};
};
