import { useEffect } from 'react';

import {
	ErrorStates,
	useBulkCreateContext,
	useSetError,
} from '../providers/BulkCreateContextProvider';
import {
	useAvailableSitesState,
	useIssueTypesActions,
	useProjectsState,
} from '../providers/BulkConfigureContextProvider';

import { fetchIssueTypes } from './apiUtils';
import { useBulkCreateLoggingEvents } from './useBulkCreateLoggingEvents';

export const FETCH_ISSUE_TYPES = 'fetch-issue-types';

export const useGetIssueTypes = () => {
	const { logRequestFailed } = useBulkCreateLoggingEvents();
	const setError = useSetError();
	const [, actions] = useBulkCreateContext();

	const { selectedAvailableSite } = useAvailableSitesState();
	const { selectedProject } = useProjectsState();
	const { setSelectedIssueTypeIfEmpty, setOptionsIssueTypes, setLoadingIssueType } =
		useIssueTypesActions();

	useEffect(() => {
		const cloudId = selectedAvailableSite?.cloudId;
		const projectId = selectedProject?.id;
		if (!cloudId || !projectId) {
			return;
		}

		const fetchIssues = async () => {
			void setLoadingIssueType(true);
			try {
				const data = await fetchIssueTypes({ cloudId, projectId });
				const validIssueTypes = data?.filter((issueType) => !issueType.subtask);
				validIssueTypes && void setOptionsIssueTypes(validIssueTypes);
				const firstIssueType = validIssueTypes?.[0];
				firstIssueType && void setSelectedIssueTypeIfEmpty(firstIssueType);
			} catch (e: Error | unknown) {
				void setError(ErrorStates.ISSUE_TYPE_RETRIEVAL);
				void actions.setManualCreateState('error');
				logRequestFailed({ requestName: FETCH_ISSUE_TYPES, errorMessage: (e as Error).message });
			} finally {
				void setLoadingIssueType(false);
			}
		};
		void fetchIssues();
	}, [
		selectedAvailableSite?.cloudId,
		selectedProject?.id,
		setLoadingIssueType,
		setOptionsIssueTypes,
		setError,
		logRequestFailed,
		setSelectedIssueTypeIfEmpty,
		actions,
	]);
};
