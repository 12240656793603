import {
	createStore,
	createStateHook,
	createActionsHook,
	createContainer,
	type StoreActionApi,
} from 'react-sweet-state';

import type { AvailableSite } from '@atlassian/gic-anywhere';

import type { FieldMetadata, JiraProject, JiraProjects } from '../__types__/apiUtils';

import type { IssueType } from './BulkCreateContextProvider';

export type Column = {
	index: number;
	header: string;
};

export type ColumnToFieldMapping = {
	column: Column | undefined;
	field: FieldMetadata | undefined;
};

type MappingValidationError = {
	message: string;
};

export type UnsavedColumnToFieldMapping = ColumnToFieldMapping & {
	isDefaultMapping: boolean;
	errors: {
		column?: MappingValidationError;
		field?: MappingValidationError;
	};
};

type BulkConfigureState = {
	optionsAvailableSites: AvailableSite[] | null;
	optionsRecentProjects: JiraProjects | null;
	optionsAllProjects: JiraProjects | null;
	optionsIssueTypes: IssueType[] | null;
	optionsIssueTypeFields: FieldMetadata[] | null;
	optionsColumns: Column[] | null;
	selectedAvailableSite: AvailableSite | null;
	selectedProject: JiraProject | null;
	selectedIssueType: IssueType | null;
	loadingProjects: boolean;
	loadingIssueType: boolean;
	loadingIssueTypeFields: boolean;
	projectSelectorQuery: string;
	isJiraError: boolean;
	columnToFieldMappings: ColumnToFieldMapping[] | null;
	unsavedColumnToFieldMappings: UnsavedColumnToFieldMapping[] | null;
	columnOffset: number | null;
};

const initialState: BulkConfigureState = {
	optionsAvailableSites: null,
	optionsRecentProjects: null,
	optionsAllProjects: null,
	optionsIssueTypes: null,
	optionsIssueTypeFields: null,
	optionsColumns: null,
	selectedAvailableSite: null,
	selectedProject: null,
	selectedIssueType: null,
	loadingProjects: false,
	loadingIssueType: false,
	loadingIssueTypeFields: false,
	projectSelectorQuery: '',
	isJiraError: false,
	columnToFieldMappings: null,
	unsavedColumnToFieldMappings: null,
	columnOffset: null,
};

const actions = {
	setOptionsAvailableSites:
		(optionsAvailableSites: AvailableSite[]) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsAvailableSites });
		},
	setOptionsRecentProjects:
		(optionsRecentProjects: JiraProjects) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsRecentProjects });
		},
	setOptionsAllProjects:
		(optionsAllProjects: JiraProjects) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsAllProjects });
		},
	setOptionsIssueTypes:
		(optionsIssueTypes: IssueType[]) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsIssueTypes });
		},
	setOptionsIssueTypeFields:
		(optionsIssueTypeFields: FieldMetadata[] | null) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsIssueTypeFields });
		},
	setOptionsColumns:
		(optionsColumns: Column[] | null) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsColumns });
		},
	setSelectedAvailableSite:
		(selectedAvailableSite: AvailableSite) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({
				selectedAvailableSite,
				selectedProject: null,
				selectedIssueType: null,
				optionsRecentProjects: null,
				optionsAllProjects: null,
				optionsIssueTypes: null,
				optionsIssueTypeFields: null,
				isJiraError: false,
			});
		},
	setSelectedProject:
		(selectedProject: JiraProject) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({
				selectedProject,
				selectedIssueType: null,
				optionsIssueTypes: null,
				optionsIssueTypeFields: null,
			});
		},
	setSelectedProjectIfEmpty:
		(newSelectedProject: JiraProject) =>
		({ getState, setState }: StoreActionApi<BulkConfigureState>) => {
			const { selectedProject } = getState();
			if (!selectedProject) {
				setState({
					selectedProject: newSelectedProject,
					selectedIssueType: null,
					optionsIssueTypes: null,
					optionsIssueTypeFields: null,
				});
			}
		},
	setSelectedIssueType:
		(selectedIssueType: IssueType) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ selectedIssueType, optionsIssueTypeFields: null });
		},
	setSelectedIssueTypeIfEmpty:
		(newSelectedIssueType: IssueType) =>
		({ getState, setState }: StoreActionApi<BulkConfigureState>) => {
			const { selectedIssueType } = getState();
			if (!selectedIssueType) {
				setState({
					selectedIssueType: newSelectedIssueType,
					optionsIssueTypeFields: null,
				});
			}
		},
	setLoadingProjects:
		(loadingProjects: boolean) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ loadingProjects });
		},
	setLoadingIssueType:
		(loadingIssueType: boolean) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ loadingIssueType });
		},
	setLoadingIssueTypeFields:
		(loadingIssueTypeFields: boolean) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ loadingIssueTypeFields });
		},
	setProjectSelectorQuery:
		(projectSelectorQuery: string) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ projectSelectorQuery });
		},
	setIsJiraError:
		(isJiraError: boolean) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ isJiraError });
		},
	setColumnToFieldMappings:
		(columnToFieldMappings: ColumnToFieldMapping[] | null) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ columnToFieldMappings });
		},
	setUnsavedColumnToFieldMappings:
		(unsavedColumnToFieldMappings: UnsavedColumnToFieldMapping[] | null) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ unsavedColumnToFieldMappings });
		},
	initUnsavedColumnToFieldMappings:
		() =>
		({ setState, getState }: StoreActionApi<BulkConfigureState>) => {
			const { columnToFieldMappings, unsavedColumnToFieldMappings, optionsIssueTypeFields } =
				getState();
			// When the issue type chenges, the issue type fields will need to be reloaded.
			// With the existing mapping:
			// If the field is not available in the newly selected issue type, we remove the mapping.
			// If the field is available, we keep the mapping and update the reference to the field.
			if (unsavedColumnToFieldMappings && optionsIssueTypeFields) {
				const fieldIdMap = new Map(optionsIssueTypeFields.map((field) => [field.fieldId, field]));
				const updatedMappings = unsavedColumnToFieldMappings.reduce<UnsavedColumnToFieldMapping[]>(
					(acc, mapping) => {
						const currentFieldId = mapping.field?.fieldId;
						if (currentFieldId === undefined || fieldIdMap.has(currentFieldId)) {
							acc.push({
								...mapping,
								field: currentFieldId ? fieldIdMap.get(currentFieldId) : undefined,
								errors: {},
							});
						}
						return acc;
					},
					[],
				);
				setState({ unsavedColumnToFieldMappings: updatedMappings });
			} else if (columnToFieldMappings) {
				const updatedMappings = columnToFieldMappings.map((mapping) => ({
					...mapping,
					isDefaultMapping: true
						? mapping.field?.fieldId === 'summary' || mapping.field?.fieldId === 'description'
						: false,
					errors: {},
				}));
				setState({ unsavedColumnToFieldMappings: updatedMappings });
			}
		},
	addUnsavedColumnToFieldMapping:
		(mapping: UnsavedColumnToFieldMapping) =>
		({ getState, setState }: StoreActionApi<BulkConfigureState>) => {
			const { unsavedColumnToFieldMappings } = getState();
			if (unsavedColumnToFieldMappings) {
				setState({ unsavedColumnToFieldMappings: [...unsavedColumnToFieldMappings, mapping] });
			}
		},
	deleteUnsavedColumnToFieldMapping:
		(index: number) =>
		({ getState, setState }: StoreActionApi<BulkConfigureState>) => {
			const { unsavedColumnToFieldMappings } = getState();
			if (unsavedColumnToFieldMappings) {
				const updatedMappings = unsavedColumnToFieldMappings.filter((_, idx) => idx !== index);
				setState({ unsavedColumnToFieldMappings: updatedMappings });
			}
		},
	saveUnsavedColumnToFieldMapping:
		() =>
		({ getState, setState }: StoreActionApi<BulkConfigureState>) => {
			const { unsavedColumnToFieldMappings } = getState();
			if (unsavedColumnToFieldMappings) {
				const updatedMappings = unsavedColumnToFieldMappings.map(
					({ errors, isDefaultMapping, ...rest }) => rest,
				);
				setState({ columnToFieldMappings: updatedMappings });
			}
		},
	setColumnOffset:
		(columnOffset: number | null) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ columnOffset });
		},
	resetAll:
		(
			selectedAvailableSite: AvailableSite,
			selectedProject: JiraProject,
			selectedIssueType: IssueType,
		) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ selectedAvailableSite, selectedProject, selectedIssueType });
		},
};

const Store = createStore<BulkConfigureState, typeof actions>({
	initialState,
	actions,
	name: 'BulkConfigureState',
});

export const BulkConfigureContextContainer = createContainer(Store);

const useActions = createActionsHook(Store);

export const useAvailableSitesActions = () => {
	const { setOptionsAvailableSites, setSelectedAvailableSite } = useActions();
	return { setOptionsAvailableSites, setSelectedAvailableSite };
};

export const useAvailableSitesState = createStateHook(Store, {
	selector: ({ optionsAvailableSites, selectedAvailableSite }: BulkConfigureState) => ({
		optionsAvailableSites,
		selectedAvailableSite,
	}),
});

export const useProjectsActions = () => {
	const {
		setOptionsRecentProjects,
		setOptionsAllProjects,
		setSelectedProject,
		setSelectedProjectIfEmpty,
		setLoadingProjects,
		setProjectSelectorQuery,
		setIsJiraError,
	} = useActions();
	return {
		setOptionsRecentProjects,
		setOptionsAllProjects,
		setSelectedProject,
		setSelectedProjectIfEmpty,
		setLoadingProjects,
		setProjectSelectorQuery,
		setIsJiraError,
	};
};

export const useProjectsState = createStateHook(Store, {
	selector: ({
		optionsRecentProjects,
		optionsAllProjects,
		selectedProject,
		projectSelectorQuery,
	}: BulkConfigureState) => ({
		optionsRecentProjects,
		optionsAllProjects,
		selectedProject,
		projectSelectorQuery,
	}),
});

export const useIssueTypesActions = () => {
	const {
		setOptionsIssueTypes,
		setSelectedIssueType,
		setLoadingIssueType,
		setSelectedIssueTypeIfEmpty,
	} = useActions();
	return {
		setOptionsIssueTypes,
		setSelectedIssueType,
		setSelectedIssueTypeIfEmpty,
		setLoadingIssueType,
	};
};

export const useIssueTypesState = createStateHook(Store, {
	selector: ({ optionsIssueTypes, selectedIssueType }: BulkConfigureState) => ({
		optionsIssueTypes,
		selectedIssueType,
	}),
});

export const useIsConfigureLoading = createStateHook(Store, {
	selector: ({
		selectedAvailableSite,
		loadingProjects,
		loadingIssueType,
		isJiraError,
	}: BulkConfigureState) => ({
		isLoading: selectedAvailableSite == null || loadingProjects || loadingIssueType,
		isJiraError,
	}),
});

export const useMappingsActions = () => {
	const {
		setOptionsColumns,
		setOptionsIssueTypeFields,
		setLoadingIssueTypeFields,
		setColumnToFieldMappings,
		setUnsavedColumnToFieldMappings,
		initUnsavedColumnToFieldMappings,
		addUnsavedColumnToFieldMapping,
		deleteUnsavedColumnToFieldMapping,
		saveUnsavedColumnToFieldMapping,
		setColumnOffset,
	} = useActions();
	return {
		setOptionsColumns,
		setOptionsIssueTypeFields,
		setLoadingIssueTypeFields,
		setColumnToFieldMappings,
		initUnsavedColumnToFieldMappings,
		setUnsavedColumnToFieldMappings,
		addUnsavedColumnToFieldMapping,
		deleteUnsavedColumnToFieldMapping,
		saveUnsavedColumnToFieldMapping,
		setColumnOffset,
	};
};

export const useMappingsState = createStateHook(Store, {
	selector: ({
		optionsColumns,
		optionsIssueTypeFields,
		loadingIssueTypeFields,
		columnToFieldMappings,
		unsavedColumnToFieldMappings,
		columnOffset,
	}: BulkConfigureState) => ({
		optionsColumns,
		optionsIssueTypeFields,
		loadingIssueTypeFields,
		columnToFieldMappings,
		unsavedColumnToFieldMappings,
		columnOffset,
	}),
});

export const useResetConfigureState = () => {
	const { resetAll } = useActions();
	return resetAll;
};
