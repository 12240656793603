import React, { Fragment, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl-next';

import { Field } from '@atlaskit/form';
import type { ValueType, SingleValueProps, OptionProps } from '@atlaskit/select';
import Select, { components } from '@atlaskit/select';

import { BulkConfigureSelectOption } from './BulkConfigureSelectOption';
import { messages } from './messages';
import {
	useIsConfigureLoading,
	useProjectsActions,
	useProjectsState,
} from './providers/BulkConfigureContextProvider';
import type { JiraProject } from './__types__/apiUtils';
import { transformStargateUrl } from './utils/configureUtils';

type ProjectOption = {
	label: string;
	value: JiraProject;
	avatarUrl: string;
};

const mapProjectToOption = (project: JiraProject): ProjectOption => {
	return {
		label: project.name,
		value: project,
		avatarUrl: transformStargateUrl(project.avatarUrls['48x48']),
	};
};

const ProjectTypeOption = (props: OptionProps<ProjectOption>) => (
	<components.Option {...props}>
		<BulkConfigureSelectOption label={props.label} avatarUrl={props.data.avatarUrl} />
	</components.Option>
);

const ProjectTypeSingleValue = (props: SingleValueProps<ProjectOption>) => (
	<components.SingleValue {...props}>
		<BulkConfigureSelectOption label={props.data.label} avatarUrl={props.data.avatarUrl} />
	</components.SingleValue>
);

export const ProjectSelector = () => {
	const { formatMessage } = useIntl();
	const { selectedProject, optionsRecentProjects, optionsAllProjects } = useProjectsState();
	const { setSelectedProject, setProjectSelectorQuery } = useProjectsActions();
	const { isLoading } = useIsConfigureLoading();

	const options = useMemo(() => {
		if (optionsRecentProjects && optionsRecentProjects.length > 0) {
			return [
				{
					label: formatMessage(messages.bulkConfigureFormProjectSelectorRecentProjects),
					options: optionsRecentProjects.map(mapProjectToOption),
				},
				{
					label: formatMessage(messages.bulkConfigureFormProjectSelectorProjects),
					options: optionsAllProjects?.map(mapProjectToOption) || [],
				},
			];
		}
		return [{ options: optionsAllProjects?.map(mapProjectToOption) || [] }];
	}, [optionsRecentProjects, optionsAllProjects, formatMessage]);

	const onChange = useCallback(
		(selectedOption: ValueType<ProjectOption>) => {
			if (selectedOption && selectedOption.value !== selectedProject) {
				void setSelectedProject(selectedOption.value);
			}
		},
		[selectedProject, setSelectedProject],
	);

	const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
	const onInputChange = useCallback(
		(query: string) => {
			if (debounceTimeoutRef.current) {
				clearTimeout(debounceTimeoutRef.current);
			}
			debounceTimeoutRef.current = setTimeout(() => {
				setProjectSelectorQuery(query);
			}, 300);
		},
		[setProjectSelectorQuery],
	);

	const selectedValue = selectedProject ? mapProjectToOption(selectedProject) : null;

	return (
		<Field<ValueType<ProjectOption>>
			name="projects"
			label={formatMessage(messages.bulkConfigureFormProject)}
			testId="bulk-create-project-field"
		>
			{({ fieldProps: { id, ...rest } }) => (
				<Fragment>
					<Select<ProjectOption>
						inputId={id}
						{...rest}
						isDisabled={isLoading || !selectedValue}
						isLoading={isLoading}
						onInputChange={onInputChange}
						value={selectedValue}
						options={options}
						onChange={onChange}
						components={{
							Option: ProjectTypeOption,
							SingleValue: ProjectTypeSingleValue,
						}}
					/>
				</Fragment>
			)}
		</Field>
	);
};
