import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import SectionMessage from '@atlaskit/section-message';

import { fg } from '@confluence/feature-gating';

import { messages } from './messages';
import { useErrorMessage } from './providers/AiBulkNavigationContextProvider';

export type BulkCreateErrorType = {
	createdCount: number;
	failedCount: number;
} | null;

export const BulkCreateErrorSectionMessage = () => {
	const errorMessage = useErrorMessage();

	if (!errorMessage) {
		return null;
	}

	const { createdCount, failedCount } = errorMessage;
	const partialCreateErrorMessageText = fg('confluence-issue-terminology-refresh')
		? messages.sidePanelIssueCreatePartialErrorMessageIssueTermRefresh
		: messages.sidePanelIssueCreatePartialErrorMessage;

	const errorMessageText = fg('confluence-issue-terminology-refresh')
		? messages.sidePanelIssueCreateErrorMessageIssueTermRefresh
		: messages.sidePanelIssueCreateErrorMessage;

	const formattedMessage =
		createdCount !== 0 ? (
			<FormattedMessage {...partialCreateErrorMessageText} />
		) : (
			<FormattedMessage {...errorMessageText} values={{ issuesCount: failedCount }} />
		);

	return (
		<SectionMessage appearance="error" testId="bulkCreateErrorSectionMessage">
			{formattedMessage}
		</SectionMessage>
	);
};
