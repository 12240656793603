import React, { Fragment, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import { Field } from '@atlaskit/form';
import type { OptionProps, SingleValueProps, ValueType } from '@atlaskit/select';
import Select, { components } from '@atlaskit/select';

import { fg } from '@confluence/feature-gating';

import { BulkConfigureSelectOption } from './BulkConfigureSelectOption';
import { messages } from './messages';
import { transformStargateUrl } from './utils/configureUtils';
import type { IssueType } from './providers/BulkCreateContextProvider';
import {
	useIsConfigureLoading,
	useIssueTypesActions,
	useIssueTypesState,
} from './providers/BulkConfigureContextProvider';

type IssueTypeOptionType = {
	label: string;
	value: IssueType;
	avatarUrl: string;
};

const mapIssueTypeToOption = (issueType: IssueType): IssueTypeOptionType => {
	return {
		label: issueType.name,
		value: issueType,
		avatarUrl: transformStargateUrl(issueType.iconUrl),
	};
};

const IssueTypeOption = (props: OptionProps<IssueTypeOptionType>) => (
	<components.Option {...props}>
		<BulkConfigureSelectOption label={props.label} avatarUrl={props.data.avatarUrl} />
	</components.Option>
);

const IssueTypeSingleValue = (props: SingleValueProps<IssueTypeOptionType>) => (
	<components.SingleValue {...props}>
		<BulkConfigureSelectOption label={props.data.label} avatarUrl={props.data.avatarUrl} />
	</components.SingleValue>
);

export const DefaultIssueTypeSelector = () => {
	const { formatMessage } = useIntl();
	const { selectedIssueType, optionsIssueTypes } = useIssueTypesState();
	const { setSelectedIssueType } = useIssueTypesActions();
	const { isLoading } = useIsConfigureLoading();

	const options = useMemo(() => {
		return optionsIssueTypes?.map(mapIssueTypeToOption);
	}, [optionsIssueTypes]);

	const onChange = useCallback(
		(selectedOption: ValueType<IssueTypeOptionType>) => {
			if (selectedOption && selectedOption.value !== selectedIssueType) {
				void setSelectedIssueType(selectedOption.value);
			}
		},
		[selectedIssueType, setSelectedIssueType],
	);

	const selectedValue = selectedIssueType ? mapIssueTypeToOption(selectedIssueType) : null;

	return (
		<Field<ValueType<IssueTypeOptionType>>
			name="defaultIssueTypes"
			label={formatMessage(
				fg('confluence-issue-terminology-refresh')
					? messages.bulkConfigureFormDefaultIssueTypeIssueTermRefresh
					: messages.bulkConfigureFormDefaultIssueType,
			)}
			testId="bulk-create-default-issue-type-field"
		>
			{({ fieldProps: { id, ...rest } }) => (
				<Fragment>
					<Select<IssueTypeOptionType>
						inputId={id}
						{...rest}
						options={options}
						isDisabled={isLoading || !selectedValue}
						isLoading={isLoading}
						value={selectedValue}
						onChange={onChange}
						components={{
							Option: IssueTypeOption,
							SingleValue: IssueTypeSingleValue,
						}}
					/>
				</Fragment>
			)}
		</Field>
	);
};
