import { Lens } from 'monocle-ts';

import {
	type AIAgentStreamingConfig,
	type UserAIAgentStreamingConfig,
} from '../../models/AIAgentStreamingConfig';

import type { AIAgentStreamingHeaders } from './AIAgentStreamingModels';
import { BASE_STREAM_URL, CHAT_STREAM_PATH } from './constants';

const baseUrl = Lens.fromNullableProp<UserAIAgentStreamingConfig>()('baseUrl', BASE_STREAM_URL);
const chatPath = Lens.fromNullableProp<UserAIAgentStreamingConfig>()('chatPath', CHAT_STREAM_PATH);
const enablePartialJson = Lens.fromNullableProp<UserAIAgentStreamingConfig>()(
	'enablePartialJson',
	false,
);
const enableFollowUp = Lens.fromNullableProp<UserAIAgentStreamingConfig>()('enableFollowUp', false);
const enableLocalVerboseLogs = Lens.fromNullableProp<UserAIAgentStreamingConfig>()(
	'enableLocalVerboseLogs',
	false,
);
const skip = Lens.fromNullableProp<UserAIAgentStreamingConfig>()('skip', false);

const contentType = Lens.fromNullableProp<AIAgentStreamingHeaders>()(
	'content-type',
	'application/json;charset=UTF-8',
);

export const getDefaultConfigWithUserSettings = (
	config: UserAIAgentStreamingConfig,
): AIAgentStreamingConfig => ({
	baseUrl: baseUrl.get(config),
	chatPath: chatPath.get(config),
	enablePartialJson: enablePartialJson.get(config),
	enableFollowUp: enableFollowUp.get(config),
	enableLocalVerboseLogs: enableLocalVerboseLogs.get(config),
	skip: skip.get(config),
	headers: {
		'content-type': contentType.get(config.headers),
		'x-product': config.headers['x-product'],
		'x-experience-id': config.headers['x-experience-id'],
		'x-issynthetic-traffic': config.headers['x-issynthetic-traffic'] ?? 'false',
	},
});
