import React, { Fragment, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl-next';

import { Field } from '@atlaskit/form';
import type { ValueType } from '@atlaskit/select';
import Select from '@atlaskit/select';

import type { AvailableSite } from '@atlassian/gic-anywhere';

import { messages } from './messages';
import {
	useAvailableSitesActions,
	useAvailableSitesState,
	useIsConfigureLoading,
} from './providers/BulkConfigureContextProvider';

interface AvailableSiteOption {
	label: string;
	value: AvailableSite;
}

const mapSiteToOption = (site: AvailableSite): AvailableSiteOption => ({
	label: site.displayName,
	value: site,
});

export const SiteSelector = () => {
	const { formatMessage } = useIntl();
	const { selectedAvailableSite, optionsAvailableSites } = useAvailableSitesState();
	const { setSelectedAvailableSite } = useAvailableSitesActions();
	const { isLoading } = useIsConfigureLoading();

	const options = useMemo(() => {
		return optionsAvailableSites?.map(mapSiteToOption);
	}, [optionsAvailableSites]);

	const onChange = useCallback(
		(selectedOption: ValueType<AvailableSiteOption>) => {
			if (selectedOption && selectedOption.value !== selectedAvailableSite) {
				void setSelectedAvailableSite(selectedOption.value);
			}
		},
		[selectedAvailableSite, setSelectedAvailableSite],
	);

	return (
		<Field<ValueType<AvailableSiteOption>>
			name="sites"
			label={formatMessage(messages.bulkConfigureFormSite)}
			testId="bulk-create-site-field"
		>
			{({ fieldProps: { id, ...rest } }) => (
				<Fragment>
					<Select<AvailableSiteOption>
						inputId={id}
						{...rest}
						value={selectedAvailableSite ? mapSiteToOption(selectedAvailableSite) : null}
						options={options}
						onChange={onChange}
						isLoading={isLoading}
						isDisabled={isLoading}
					/>
				</Fragment>
			)}
		</Field>
	);
};
