import { useEffect } from 'react';

import type { Column } from '../providers/BulkConfigureContextProvider';
import { useMappingsActions } from '../providers/BulkConfigureContextProvider';
import { useIssueCreateSidePanelContext } from '../providers/IssueCreateSidePanelContextProvider';

export const useSetOptionsColumns = () => {
	const { setOptionsColumns, setColumnOffset } = useMappingsActions();
	const [{ selectedTable }] = useIssueCreateSidePanelContext();

	useEffect(() => {
		if (selectedTable && selectedTable.rows.length > 0) {
			const columns: Column[] = [];
			// Always use the first row as the header row, even if it is not a HTML header row
			const firstRow = selectedTable?.querySelector('tbody tr:first-child');
			if (firstRow) {
				const firstRowCells = Array.from(firstRow.querySelectorAll('td, th'));
				const hasNumberedCol = firstRowCells[0].classList.contains(
					'ak-renderer-table-number-column',
				)
					? 1
					: 0;
				const hasHeaderCol =
					selectedTable.rows.length > 1 &&
					Array.from(selectedTable.rows).every(
						(row) =>
							row.cells.length > 0 &&
							(row.cells[0].tagName.toLowerCase() === 'th' ||
								row.cells[1].tagName.toLowerCase() === 'th'),
					)
						? 1
						: 0;
				// The API does not index the header column
				setColumnOffset(hasHeaderCol);
				let columnIndex = hasNumberedCol + hasHeaderCol;
				// Skip the header and numbered column
				firstRowCells.slice(hasNumberedCol + hasHeaderCol).forEach((cell) => {
					columns.push({ index: columnIndex, header: cell.textContent ?? '' });
					columnIndex += 1;
				});
			}
			setOptionsColumns(columns);
		}
	}, [selectedTable, setColumnOffset, setOptionsColumns]);
};
