import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import { messages } from './messages';
import { AiErrorImage } from './assets/AiIssueCreateErrorImage';

export interface ManualBulkCreationErrorProps {
	errorMessage: { id: string; defaultMessage: string; description: string };
	errorMessageValues?: Record<string, any>;
	onTryAgain?: () => void;
}

const errorTryAgainTestId = 'error-try-again-button';

const errorContainerStyle = xcss({
	flexGrow: '1',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-start',
	paddingInline: 'space.800',
});

const errorDescriptionStyle = xcss({
	paddingBlock: 'space.300',
	font: token('font.body.large'),
	fontWeight: token('font.weight.regular'),
	color: 'color.text.subtle',
});

const buttonLayoutStyle = xcss({
	width: '100%',
	paddingBlock: 'space.050',
});

export const ManualBulkCreationError = ({
	errorMessage,
	errorMessageValues,
	onTryAgain,
}: ManualBulkCreationErrorProps) => {
	return (
		<Box xcss={errorContainerStyle}>
			<Box>
				<AiErrorImage />
			</Box>
			<Box testId="error-description" xcss={errorDescriptionStyle}>
				<FormattedMessage {...errorMessage} values={errorMessageValues} />
			</Box>
			{onTryAgain && (
				<Box xcss={buttonLayoutStyle}>
					<Button testId={errorTryAgainTestId} onClick={onTryAgain} appearance="default">
						<FormattedMessage {...messages.aiErrorTryAgainButton} />
					</Button>
				</Box>
			)}
		</Box>
	);
};
