import { useEffect, useRef, useState } from 'react';

import { di } from 'react-magnetic-di';

import { type AvailableSite, getAvailableJiraSites } from '../../common/utils';

import {
	type GetFirstAvailableSiteDomainCallbackProps,
	type GetFirstAvailableSiteDomainNewProps,
	type GetFirstAvailableSiteDomainProps,
} from './types';

export const useGetFirstAvailableSiteDomain = ({
	preferredCloudId,
	onComplete,
	onError,
}: GetFirstAvailableSiteDomainProps) => {
	di(getAvailableJiraSites);
	const [domain, setDomain] = useState<string | undefined>(undefined);
	const [availableSites, setAvailableSites] = useState<AvailableSite[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);

	useEffect(() => {
		const fetchAvailableJiraSites = async () => {
			setLoading(true);
			try {
				const { sites } = await getAvailableJiraSites();
				setAvailableSites(sites ?? []);
				const computedDomain =
					(sites.find((site) => site.cloudId === preferredCloudId) ?? sites[0])?.url ?? '';
				setDomain(computedDomain);
				onComplete?.({ domain: computedDomain, availableSites: sites });
			} catch (e: Error | unknown) {
				onError && onError(e as Error);
				setError((e as Error).message);
			} finally {
				setLoading(false);
			}
		};
		void fetchAvailableJiraSites();
	}, [preferredCloudId, onComplete, onError]);

	return { domain, availableSites, loading, error };
};

export const useGetJiraSites = ({
	onComplete,
	onError,
}: GetFirstAvailableSiteDomainCallbackProps) => {
	di(getAvailableJiraSites);

	const [availableSites, setAvailableSites] = useState<AvailableSite[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const hasMount = useRef(false);

	useEffect(() => {
		if (hasMount.current) {
			return;
		}
		setLoading(true);
		const fetchAvailableJiraSites = async () => {
			try {
				const { sites } = await getAvailableJiraSites();
				setAvailableSites(sites ?? []);
				onComplete?.({ availableSites: sites });
			} catch (e: Error | unknown) {
				onError && onError(e as Error);
				setError((e as Error).message);
			} finally {
				setLoading(false);
			}
		};

		void fetchAvailableJiraSites();
		hasMount.current = true;
	}, [onComplete, onError]);

	return { availableSites, loading, error };
};

export const getFirstAvailableSiteDomain = ({
	sites,
	preferredCloudId,
}: GetFirstAvailableSiteDomainNewProps) => {
	const domain = (sites.find((site) => site.cloudId === preferredCloudId) ?? sites[0])?.url ?? '';

	return { domain };
};

export const useGetDomainAndAvailableSites = ({
	cloudId,
	overrideCloudId,
}: {
	cloudId: string;
	overrideCloudId: string | undefined;
}) => {
	const { availableSites } = useGetJiraSites({});

	const { domain: domainName } = getFirstAvailableSiteDomain({
		sites: availableSites,
		preferredCloudId: cloudId,
	});

	const { domain: isOverrideSiteAvailable } = getFirstAvailableSiteDomain({
		sites: availableSites,
		preferredCloudId: overrideCloudId,
	});

	return { availableSites, domainName, isOverrideSiteAvailable };
};
