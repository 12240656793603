import React, { useCallback } from 'react';

import { Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';

import { fg } from '@confluence/feature-gating';

import { BulkCreateButtonGroup } from './BulkCreateButtonGroup';
import { BulkCreateBody } from './BulkCreateBody';
import { BulkCreateHeader } from './BulkCreateHeader';
import { SidePanelWrapper } from './SidePanelWrapper';
import { ManualBulkCreationError } from './ManualBulkCreationError';
import { messages } from './messages';
import { useManualBulkCreateState } from './utils/useManualBulkCreateState';
import { useBulkCreateContext } from './providers/BulkCreateContextProvider';
import { useGetIssueInsertionActions } from './utils/useGetIssueInsertionActions';

const contentStyles = xcss({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
});

const footerBaseStyles = xcss({
	backgroundColor: 'elevation.surface',
	flexShrink: 0,
	position: 'relative',
	'::before': {
		content: '""',
		position: 'absolute',
		top: 'space.0',
		left: 'space.150',
		right: 'space.150',
		height: '1px',
		backgroundColor: 'elevation.surface',
		borderTopColor: 'color.border',
		borderTopStyle: 'solid',
		borderTopWidth: 'border.width',
	},
});

const footerTopStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.150',
	paddingLeft: 'space.200',
	paddingRight: 'space.200',
});

const loadingScreenStyles = xcss({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center',
});

export const BulkCreateDefaultView = ({ onClose }: { onClose: () => void }) => {
	const { isLoading, isError, isIdle } = useManualBulkCreateState();
	const [, actions] = useBulkCreateContext();
	const { addSingleIssuesToPage } = useGetIssueInsertionActions();

	// TO-DO need to implement logic to retry fetching Jira site / project / issue type
	// const handleTryAgain = () => {}

	const onPanelClose = useCallback(() => {
		addSingleIssuesToPage();
		//reset manual bulk state, bulk issue list and error state if FG is enabled
		if (fg('confluence_bulk_issue_creation_panel_state_bug_fix')) {
			void actions.resetManualBulkCreateState();
		}
		onClose();
	}, [actions, addSingleIssuesToPage, onClose]);

	return (
		<SidePanelWrapper>
			<Stack xcss={contentStyles}>
				<BulkCreateHeader onClose={onPanelClose} />
				{isLoading && (
					<Box xcss={loadingScreenStyles}>
						<Spinner size="large" />
					</Box>
				)}
				{isError && (
					<ManualBulkCreationError
						errorMessage={messages.connectJiraProjectErrorDescription}
						// set onTryAgain when handleTryAgain is implemented
					/>
				)}
				{isIdle && (
					<>
						<BulkCreateBody />
						<Box xcss={footerBaseStyles}>
							<Inline
								alignInline="center"
								grow="fill"
								spread="space-between"
								xcss={footerTopStyles}
							>
								<BulkCreateButtonGroup onClose={onPanelClose} onCreateAll={onClose} />
							</Inline>
						</Box>
					</>
				)}
			</Stack>
		</SidePanelWrapper>
	);
};
