import {
	createStore,
	createStateHook,
	createActionsHook,
	createContainer,
	type StoreActionApi,
} from 'react-sweet-state';

export const WarningDialog = {
	NAV: 'NAV',
	FLOATING: 'FLOATING',
} as const;

type WarningDialogType = keyof typeof WarningDialog;

type WarningDialogState = {
	isWarningDialogOpen: boolean;
	warningDialog: WarningDialogType;
};
export const initialWarningDialogState = {
	isWarningDialogOpen: false,
	warningDialog: WarningDialog.NAV,
};

export const actions = {
	showWarningDialog:
		(warningDialogDisplayType?: WarningDialogType) =>
		({ setState }: StoreActionApi<WarningDialogState>) => {
			const warningDialog = warningDialogDisplayType || WarningDialog.NAV;
			setState({ isWarningDialogOpen: true, warningDialog });
		},
	hideWarningDialog:
		() =>
		({ setState }: StoreActionApi<WarningDialogState>) => {
			setState({ isWarningDialogOpen: false, warningDialog: WarningDialog.NAV });
		},
};
type WarningDialogActionType = typeof actions;

const Store = createStore<WarningDialogState, WarningDialogActionType>({
	initialState: initialWarningDialogState,
	actions,
	name: 'WarningDialogState',
});

export const IssueCreateWarningDialogContainer = createContainer(Store);

export const useWarningDialogStateActions = createActionsHook(Store);

export const useIsWarningDialogOpen = createStateHook(Store, {
	selector: (state: WarningDialogState) => state.isWarningDialogOpen,
});

export const useGetWarningDialog = createStateHook(Store, {
	selector: (state: WarningDialogState) => state.warningDialog,
});
