import { useEffect } from 'react';

import { fg } from '@confluence/feature-gating';

import { ErrorStates, useBulkCreateContext } from '../providers/BulkCreateContextProvider';
import { useMappingsState } from '../providers/BulkConfigureContextProvider';

export const useManualBulkCreateState = () => {
	const [
		{
			currentJiraSite,
			currentJiraProject,
			currentDefaultIssueType,
			manualCreateState,
			error,
			bulkIssuesList,
		},
		actions,
	] = useBulkCreateContext();

	const { loadingIssueTypeFields } = useMappingsState();

	useEffect(() => {
		const isLoading =
			!currentJiraSite ||
			!currentJiraProject ||
			!currentDefaultIssueType ||
			loadingIssueTypeFields ||
			(bulkIssuesList?.length === 0 && fg('confluence_bulk_issue_creation_panel_state_bug_fix'));
		if (isLoading) {
			void actions.setManualCreateState('loading');
		}

		const isError = error !== ErrorStates.EMPTY;
		if (isError && fg('confluence_bulk_issue_creation_panel_state_bug_fix')) {
			void actions.setManualCreateState('error');
		}

		if (!isLoading && !isError) {
			void actions.setManualCreateState('idle');
		}
	}, [
		actions,
		currentDefaultIssueType,
		currentJiraProject,
		currentJiraSite,
		loadingIssueTypeFields,
		error,
		bulkIssuesList,
	]);

	return {
		isLoading: manualCreateState === 'loading',
		isError: manualCreateState === 'error',
		isIdle: manualCreateState === 'idle',
	};
};
