import { useCallback } from 'react';

import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';

import { constructJiraIssueUrl } from './issueInsertionUtils';

type JiraIssueKey = {
	key: string;
};

type GetJiraIssuesLinkPayload = JiraIssueKey[];

export const useGetJiraIssuesLink = () => {
	const [{ currentJiraSite }] = useBulkCreateContext();

	return useCallback(
		(issues: GetJiraIssuesLinkPayload) => {
			const currentSiteUrl = currentJiraSite?.url;

			if (issues.length > 1) {
				const issueKeys = issues.map((issue) => issue.key).join(',');
				const jqlSearch = encodeURIComponent(`issueKey in (${issueKeys})`);
				return `${currentSiteUrl}/issues/?jql=${jqlSearch}`;
			}
			return constructJiraIssueUrl(currentSiteUrl, issues[0].key);
		},
		[currentJiraSite],
	);
};
