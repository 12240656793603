import { useEffect } from 'react';

import {
	ErrorStates,
	useSetError,
	useBulkCreateContext,
} from '../providers/BulkCreateContextProvider';
import {
	useAvailableSitesState,
	useProjectsActions,
	useProjectsState,
} from '../providers/BulkConfigureContextProvider';
import type { JiraProjects } from '../__types__/apiUtils';

import { fetchJiraProjects, fetchRecentJiraProjects } from './apiUtils';
import { useBulkCreateLoggingEvents } from './useBulkCreateLoggingEvents';

export const FETCH_JIRA_PROJECTS = 'fetch-jira-projects';
export const FETCH_RECENT_JIRA_PROJECTS = 'fetch-recent-jira-projects';

export const useGetJiraProjects = () => {
	const { logRequestFailed } = useBulkCreateLoggingEvents();
	const setError = useSetError();
	const [, actions] = useBulkCreateContext();

	const { selectedAvailableSite } = useAvailableSitesState();
	const {
		setSelectedProjectIfEmpty,
		setOptionsRecentProjects,
		setOptionsAllProjects,
		setLoadingProjects,
		setIsJiraError,
	} = useProjectsActions();
	const { projectSelectorQuery: query } = useProjectsState();

	useEffect(() => {
		const cloudId = selectedAvailableSite?.cloudId;
		if (!selectedAvailableSite || (query && query.trim().length > 0)) {
			return;
		}

		const fetchProjects = async () => {
			void setLoadingProjects(true);

			const [recentProjectsPromise, allProjectsPromise] = await Promise.allSettled([
				fetchRecentProjects(),
				fetchAllProjects(),
			]);
			const firstProject =
				getFirstFromPromise(recentProjectsPromise) || getFirstFromPromise(allProjectsPromise);

			if (firstProject) {
				void setSelectedProjectIfEmpty(firstProject);
			} else {
				void setIsJiraError(true);
			}
			void setLoadingProjects(false);
		};

		const getFirstFromPromise = (result: PromiseSettledResult<JiraProjects | undefined>) => {
			return result.status === 'fulfilled' ? result.value?.[0] : undefined;
		};

		const fetchAllProjects = async () => {
			try {
				const allProjects = await fetchJiraProjects({ cloudId });
				if (allProjects) {
					void setOptionsAllProjects(allProjects);
				}
				return allProjects;
			} catch (e) {
				logRequestFailed({
					requestName: FETCH_JIRA_PROJECTS,
					errorMessage: `Failed to fetch projects: ${(e as Error).message}`,
				});
				void setError(ErrorStates.PROJECT_RETRIEVAL);
				void actions.setManualCreateState('error');
			}
		};

		const fetchRecentProjects = async () => {
			try {
				const recentProjects = await fetchRecentJiraProjects({ cloudId });

				const recentProjectIds = recentProjects?.map((project) => project.id);
				if (!recentProjectIds || recentProjectIds.length === 0) {
					return;
				}

				const recentProjectsWithIssueCreatePermissionData = await fetchJiraProjects({
					cloudId,
					projectIds: recentProjectIds,
				});
				if (recentProjectsWithIssueCreatePermissionData) {
					void setOptionsRecentProjects(recentProjectsWithIssueCreatePermissionData);
				}
				return recentProjectsWithIssueCreatePermissionData;
			} catch (e) {
				logRequestFailed({
					requestName: FETCH_RECENT_JIRA_PROJECTS,
					errorMessage: `Failed to fetch recent projects with issue create permission: ${(e as Error).message}`,
				});
				void setError(ErrorStates.PROJECT_RETRIEVAL);
				void actions.setManualCreateState('error');
			}
		};

		void fetchProjects();
	}, [
		selectedAvailableSite,
		setLoadingProjects,
		setOptionsAllProjects,
		logRequestFailed,
		setError,
		setOptionsRecentProjects,
		setSelectedProjectIfEmpty,
		query,
		setIsJiraError,
		actions,
	]);

	useEffect(() => {
		const cloudId = selectedAvailableSite?.cloudId;
		if (!selectedAvailableSite || !query || query.trim().length === 0) {
			return;
		}
		const fetchProjects = async () => {
			try {
				const allProjects = await fetchJiraProjects({ cloudId, query });
				if (allProjects) {
					void setOptionsRecentProjects([]);
					void setOptionsAllProjects(allProjects);
				}
				return allProjects;
			} catch (e) {
				logRequestFailed({
					requestName: FETCH_JIRA_PROJECTS,
					errorMessage: `Failed to fetch query projects: ${(e as Error).message}`,
				});
			}
		};

		void fetchProjects();
	}, [
		selectedAvailableSite,
		setOptionsAllProjects,
		logRequestFailed,
		setOptionsRecentProjects,
		query,
	]);
};
