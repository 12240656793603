import { useCallback } from 'react';

import { useSetIsCreatedWithAi } from '../providers/BulkCreateContextProvider';
import { useWarningDialogStateActions } from '../providers/IssueCreateWarningDialogContextProvider';

export const useGetWarningDialogRetry = () => {
	const { hideWarningDialog } = useWarningDialogStateActions();
	const setIsCreatedWithAi = useSetIsCreatedWithAi();

	const handleConfirm = useCallback(async () => {
		// This logic will be replaced by a refetch call to the AI API which will render new AI issues
		await setIsCreatedWithAi(false);
		await setIsCreatedWithAi(true);
		hideWarningDialog();
	}, [hideWarningDialog, setIsCreatedWithAi]);

	const handleCancel = useCallback(() => {
		hideWarningDialog();
	}, [hideWarningDialog]);

	return {
		handleConfirm,
		handleCancel,
	};
};
