import type { UserAIAgentStreamingConfig } from '../../models/AIAgentStreamingConfig';

import type { AIAgentStreamingRequest } from './AIAgentStreamingModels';

export type AIAgentRequestConfig = {
	requestInitParams: RequestInit;
	url: string;
};

export const createAIAgentRequestConfig = (
	request: AIAgentStreamingRequest,
	config: UserAIAgentStreamingConfig,
): AIAgentRequestConfig => {
	const requestInitParams: RequestInit = {
		method: 'POST',
		headers: new Headers({
			...config.headers,
		}),
		body: JSON.stringify(request),
	};

	return {
		requestInitParams,
		url: `${config.baseUrl}/${config.chatPath}`,
	};
};
