import { useEffect } from 'react';

import { fg } from '@confluence/feature-gating';

import { useMappingsState } from '../providers/BulkConfigureContextProvider';
import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';
import { useIssueCreateSidePanelContext } from '../providers/IssueCreateSidePanelContextProvider';
import type { InsertStorageColumnTableRequestPayload } from '../__types__/InsertStorageColumnTableRequestPayload';
import { useAiBulkNavigationGotoPage } from '../providers/AiBulkNavigationContextProvider';

import { mapTableToIssues } from './tableUtils';

export const useMapTableToIssues = () => {
	const { columnToFieldMappings, columnOffset } = useMappingsState();
	const [
		{ selectedTable, insertStorageColumnTableRequestPayload },
		{ setInsertStorageColumnTableRequestPayload },
	] = useIssueCreateSidePanelContext();
	const [, { setBulkIssuesList }] = useBulkCreateContext();
	const goToPage = useAiBulkNavigationGotoPage();
	useEffect(() => {
		if (columnToFieldMappings && columnToFieldMappings.length > 0 && selectedTable) {
			const mappedIssues = mapTableToIssues(columnToFieldMappings, selectedTable);
			void setBulkIssuesList(mappedIssues);
			if (mappedIssues.length === 0 && fg('confluence_bulk_issue_creation_loading_fix')) {
				void goToPage('configure');
			}
		}
	}, [columnToFieldMappings, goToPage, selectedTable, setBulkIssuesList]);

	useEffect(() => {
		if (columnToFieldMappings && columnToFieldMappings.length > 0) {
			const summaryMapping = columnToFieldMappings.find(
				(mapping) => mapping.field?.key === 'summary',
			);
			//The insertion API does not include header columns in indexing
			const unadjustedIndex = summaryMapping?.column?.index ?? 0;
			const tableColumnIndex =
				columnOffset != null && unadjustedIndex !== 0
					? unadjustedIndex - columnOffset
					: unadjustedIndex;
			const updatedPayload = {
				...insertStorageColumnTableRequestPayload,
				tableColumnIndex,
			} as InsertStorageColumnTableRequestPayload;
			void setInsertStorageColumnTableRequestPayload(updatedPayload);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnToFieldMappings]);
};
