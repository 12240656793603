import { useEffect } from 'react';

import { fg } from '@confluence/feature-gating';

import {
	useCloseAndAddIssuesToPage,
	useResetCloseAndAddIssuesToPage,
} from '../providers/BulkCreateContextProvider';

import { useGetIssueInsertionActions } from './useGetIssueInsertionActions';

export const useHandleCloseAndAddIssuesToPage = (onClose: () => void) => {
	const shouldClose = useCloseAndAddIssuesToPage();
	const { addSingleIssuesToPage } = useGetIssueInsertionActions();
	const resetCloseAndAddIssuesToPage = useResetCloseAndAddIssuesToPage();

	useEffect(() => {
		if (shouldClose && fg('close_side_panel_on_final_single_issue_creation')) {
			void addSingleIssuesToPage();
			onClose();
			// Reset the state after closing
			void resetCloseAndAddIssuesToPage();
		}
	}, [shouldClose, onClose, addSingleIssuesToPage, resetCloseAndAddIssuesToPage]);
};
