import type { AvailableSite } from '@atlassian/gic-anywhere';

import {
	confluenceLocalStorageInstance as localStorage,
	PERSISTED_KEYS_ON_SERVER,
	keys as localStorageKeys,
} from '@confluence/storage-manager';

import type { IssueType } from '../providers/BulkCreateContextProvider';
import type { JiraProject } from '../__types__/apiUtils';

export enum ISSUE_CREATE_WITH_AI {
	enabled = 'enabled',
	disabled = 'disabled',
}

export const saveCloudIdToLocalStorage = (payload: { selectedCloudId: string }) => {
	if (payload?.selectedCloudId) {
		try {
			localStorage.setItem(
				PERSISTED_KEYS_ON_SERVER.PERSISTED_JIRA_CLOUD_ID_PREFERENCE,
				payload.selectedCloudId,
			);
		} catch (error: unknown) {
			throw new Error('Unable to fetch cloudId');
		}
	}
};

export const getPersistedOverrideCloudId = () =>
	localStorage?.getItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_JIRA_CLOUD_ID_PREFERENCE);

export const getIsCreateIssueWithAiEnabledByUser = () => {
	const isCreateIssueAiEnabled = localStorage?.getItem(localStorageKeys.ISSUE_CREATE_WITH_AI);
	// If the value is not set true should be returned by default
	return isCreateIssueAiEnabled !== ISSUE_CREATE_WITH_AI.disabled;
};

export const setCreateIssueWithAiEnabledByUser = (enabled: boolean) => {
	localStorage?.setItem(
		localStorageKeys.ISSUE_CREATE_WITH_AI,
		enabled ? ISSUE_CREATE_WITH_AI.enabled : ISSUE_CREATE_WITH_AI.disabled,
	);
};

export const saveJiraConfigDetailsToLocalStorage = ({
	selectedSite,
	selectedProject,
	selectedIssueType,
}: {
	selectedSite?: AvailableSite | null;
	selectedProject?: JiraProject | null;
	selectedIssueType?: IssueType | null;
}) => {
	try {
		if (selectedSite && selectedProject && selectedIssueType) {
			localStorage.setItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_BULK_CREATE_JIRA_CONFIG_FOR_USER, {
				selectedSite,
				selectedProject,
				selectedIssueType,
			});
		}
	} catch (error: unknown) {
		// eslint-disable-next-line no-console
		console.error(
			`Unable to set bulk create Jira config: ${error instanceof Error ? error.message : String(error)}`,
		);
	}
};
