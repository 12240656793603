import { useEffect } from 'react';

import type { Column, ColumnToFieldMapping } from '../providers/BulkConfigureContextProvider';
import { useMappingsActions, useMappingsState } from '../providers/BulkConfigureContextProvider';
import type { FieldMetadata } from '../__types__/apiUtils';

const createDefaultMappings = (
	columns: Column[],
	fields: FieldMetadata[],
): ColumnToFieldMapping[] => {
	const fieldMap: { [key: number]: string } = {
		0: 'summary',
		1: 'description',
	};
	return columns.slice(0, 2).map((column, index) => {
		const fieldId = fieldMap[index];
		const field = fields.find((f) => f.fieldId === fieldId);
		return { column, field };
	});
};

export const useInitMappings = () => {
	const { optionsIssueTypeFields, optionsColumns } = useMappingsState();
	const { setColumnToFieldMappings, initUnsavedColumnToFieldMappings } = useMappingsActions();
	useEffect(() => {
		if (optionsIssueTypeFields && optionsColumns) {
			const defaultMappings = createDefaultMappings(optionsColumns, optionsIssueTypeFields);
			setColumnToFieldMappings(defaultMappings);
			initUnsavedColumnToFieldMappings();
		}
	}, [
		optionsIssueTypeFields,
		optionsColumns,
		setColumnToFieldMappings,
		initUnsavedColumnToFieldMappings,
	]);
};
