import React, { useEffect } from 'react';

import { BulkEditIssueView } from './BulkEditIssueView';
import { AiBulkDefaultView } from './AiBulkDefaultView';
import { BulkConfigureView } from './BulkConfigureView';
import { useAiBulkFetchIssuesList } from './utils/useAiBulkFetchIssuesList';
import { BulkCreateFlagsContainer } from './BulkCreateFlagsContainer';
import { useBulkCreateLoggingEvents } from './utils/useBulkCreateLoggingEvents';

export const AiBulkNavigationContainer = ({ onClose }: { onClose: () => void }) => {
	const { logAiInteractionInitiated } = useBulkCreateLoggingEvents();

	useEffect(() => {
		logAiInteractionInitiated();
	}, [logAiInteractionInitiated]);

	useAiBulkFetchIssuesList();

	return (
		<>
			<AiBulkDefaultView onClose={onClose} />
			<BulkConfigureView />
			<BulkEditIssueView />
			<BulkCreateFlagsContainer />
		</>
	);
};
