import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import type { DocNode } from '@atlaskit/adf-schema';

import { AIAgentResponseState, useAIAgentStreaming } from '@atlassian/ai-agent-streaming';
import { adfToMarkdownPlus, markdownPlusToAdf } from '@atlassian/adf-markdown-transformer';
import type { IDMap } from '@atlassian/ai-model-io/convert-prosemirror-to-markdown';
import type { AiIssueCreateState } from '@atlassian/ai-issue-create';

import { usePageContentId } from '@confluence/page-context';
import { fg } from '@confluence/feature-gating';

import type { Issue, JiraAIRequest, JiraAIResponse } from '../__types__/AiAgentStreamingTypes';
import { AiStates, useBulkCreateContext } from '../providers/BulkCreateContextProvider';

import type {
	BulkIssueADFQuery as BulkIssueADFQueryType,
	BulkIssueADFQueryVariables,
} from './ADFtoMarkdownResources/__types__/BulkIssueADFQuery';
import { BulkIssueADFQuery } from './ADFtoMarkdownResources/BulkIssueADFQuery.graphql';
import { useBulkCreateLoggingEvents } from './useBulkCreateLoggingEvents';

export const useAIAgentStreamingData = () => {
	const [{ bulkIssuesList }, { setBulkIssuesList }] = useBulkCreateContext();
	const [markdownPlusContent, setMarkdownPlusContent] = useState('');
	const [pageIdMap, setPageIdMap] = useState<IDMap | undefined>(undefined);
	const [currentAiState, setCurrentAiState] = useState<AiIssueCreateState>(AiStates.LOADING);
	const { logAiStreamingBackendFailed } = useBulkCreateLoggingEvents();

	const [contentId] = usePageContentId();
	const { logAiNoResultsGenerated } = useBulkCreateLoggingEvents();

	const pageId = contentId ?? '';

	// Used to keep track of the issues that have been added to the list and give a unique id
	const issueIndex = useRef(0);

	// Needed to convert confluence content into MD+ format for the ai request
	useQuery<BulkIssueADFQueryType, BulkIssueADFQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		BulkIssueADFQuery,
		{
			variables: {
				contentId: pageId,
			},
			onError: (_adfError) => {
				setCurrentAiState(AiStates.ERROR);
			},
			fetchPolicy: 'network-only',
			onCompleted: (adfData) => {
				const adf = adfData?.singleContent?.body?.atlas_doc_format?.value;
				if (adf) {
					try {
						const parsedAdf: DocNode = JSON.parse(adf);
						const { markdown: markdownPlus, idMap } = adfToMarkdownPlus(parsedAdf);
						// Handle pages that don't have any content
						if (!markdownPlus) {
							setCurrentAiState(AiStates.ERROR);
							return;
						}
						setMarkdownPlusContent(markdownPlus);
						setPageIdMap(idMap);
					} catch (err) {
						setCurrentAiState(AiStates.ERROR);
					}
				}
			},
			skip: currentAiState !== AiStates.LOADING,
		},
	);

	const request: JiraAIRequest = {
		recipient_agent_named_id: 'ai_issue_create_agent',
		agent_input_context: {
			application: 'Confluence',
			context: {
				confluence_page_markdown_plus: markdownPlusContent,
				highlighted_text: null,
			},
			suggested_issues_config: {
				max_issues: 10,
				suggested_issue_field_types: [
					{
						issue_type: 'Task',
						fields: [
							{
								field_name: 'Summary',
								field_type: 'Short-Text',
							},
							{
								field_name: 'Description',
								field_type: 'Paragraph',
							},
						],
					},
				],
			},
		},
	};

	const [{ content, responseState, error }] = useAIAgentStreaming<JiraAIRequest, JiraAIResponse>(
		request,
		{
			enablePartialJson: true,
			skip: !markdownPlusContent,
			headers: {
				'x-experience-id': 'ai-issue-create-confluence-bulk',
				'x-product': 'confluence',
			},
		},
	);
	useEffect(() => {
		const getIssueDetails = () => {
			return {
				iconUrl: '',
				name: '',
				id: '',
			};
		};

		const isValidIssue = (issue: Issue) => {
			return issue?.field_values?.Summary && issue?.field_values?.Description;
		};

		// Temp add issues until we can handle partial responses
		const addIssues = (issues: Issue[]) => {
			const validIssues = issues.filter((issue) => isValidIssue(issue)) ?? [];
			const newIssues = validIssues.map((issue) => ({
				id: String(issueIndex.current++),
				summary: issue.field_values.Summary,
				description: markdownPlusToAdf({
					markdown: issue.field_values.Description,
					idMap: pageIdMap,
				}),
				issueType: getIssueDetails(),
			}));

			void setBulkIssuesList(newIssues);
		};

		// Switch statement to handle the different response states
		switch (responseState) {
			case AIAgentResponseState.Initialization:
				// Not needed really just want to track all the states
				void setBulkIssuesList([]);
				break;
			case AIAgentResponseState.AnswerPart:
				if (fg('confluence_ai_bulk_issue_creation_handle_chunks')) {
					try {
						const issueStream = content?.suggested_issues;
						// since objects within each JSON are periodically streamed in
						// we want to only insert when its fully formed.
						if (issueStream && issueStream.length > issueIndex.current) {
							addIssues(issueStream);
							setCurrentAiState(AiStates.IDLE);
						}
					} catch (error) {
						setCurrentAiState(AiStates.ERROR);
						logAiStreamingBackendFailed(error);
					}
				}
				break;
			case AIAgentResponseState.FinalResponse:
				const issues = content.suggested_issues;
				try {
					if (issues.length === 0) {
						logAiNoResultsGenerated();
						setCurrentAiState(AiStates.ERROR);
					} else {
						// Setting list when all issues are returned for now, will update to handle partial responses
						addIssues(issues);
						setCurrentAiState(AiStates.IDLE);
					}
				} catch (error) {
					setCurrentAiState(AiStates.ERROR);
					logAiStreamingBackendFailed(error);
				}
				break;
			case AIAgentResponseState.Error: {
				setCurrentAiState(AiStates.ERROR);
				logAiStreamingBackendFailed(error);
			}
			default:
				break;
		}
	}, [
		content,
		pageIdMap,
		responseState,
		issueIndex,
		error,
		logAiStreamingBackendFailed,
		logAiNoResultsGenerated,
		setBulkIssuesList,
	]);

	return {
		content: bulkIssuesList,
		currentAiState,
		responseState,
	};
};
