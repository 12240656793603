export interface AIAgentStreamingHeaders {
	'content-type'?: string;
	/*
	 * x-experience-id is required header for the assistance-service
	 * it should be defined here for your feature
	 *	https://bitbucket.org/atlassian/assistance-service/src/b2f20cfb1bfe042a2e120f14e601bc52d7c3331d/src/schema/base.py#lines-51
	 */
	'x-experience-id': string;
	/*
	 * x-product is required header for the assistance-service
	 * it should be defined here for your feature
	 *  https://bitbucket.org/atlassian/assistance-service/src/b2f20cfb1bfe042a2e120f14e601bc52d7c3331d/src/schema/base.py#lines-25
	 */
	'x-product': string;
	/**
	 * x-issynthetic-traffic is an optional header for assistance-service
	 * to determine if reqest is synthetic or not. by not passing it in,
	 * it will mean that your synthetic requests will be logged as "real" experiences in SFX
	 * Defaults to false.
	 * https://bitbucket.org/atlassian/assistance-service/src/209f97626770dfe09d523ac8605df64353c09a67/src/schema/execution_context.py?at=master#execution_context.py-208,369,397
	 *
	 */
	'x-issynthetic-traffic'?: string;
}

export interface AIAgentStreamingRequest<TAgentInputContext = object> {
	recipient_agent_named_id: string;
	agent_input_context: TAgentInputContext;
}

export interface AssistanceServiceStreamingRequest<InputContent = object | string> {
	recipient_agent_named_id: string;
	content: InputContent;
}

export enum ErrorMessage {
	'NETWORK_ERROR' = 'NETWORK_ERROR',
	'NO_ANSWER' = 'NO_ANSWER',
	'RATE_LIMIT' = 'RATE_LIMIT',
	// typo comes from the backend
	// https://bitbucket.org/atlassian/assistance-service/src/02087c3b6569f042ec338e08e8e0e152c4563dad/src/schema/message_publisher.py#lines-74
	'ANSWER_UNAVAILABLE_FOR_CONTENT' = 'ASWER_UNAVAILABLE_FOR_CONTENT',
	'NO_AGENT' = 'NO_AGENT',
	'PLUGIN_ERRORED' = 'PLUGIN_ERRORED',
	'OPENAI_RATE_LIMIT_USER_ABUSE' = 'OPENAI_RATE_LIMIT',
	'ACCEPTABLE_USE_VIOLATIONS' = 'ACCEPTABLE_USE_VIOLATIONS',
	'AI_DISABLED' = 'AI_DISABLED',
	'UNEXPECTED' = 'UNEXPECTED',
	'NOT_ENOUGH_CONTENT_ERROR' = 'NOT_ENOUGH_CONTENT_ERROR',
}
