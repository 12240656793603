import {
	WarningDialog,
	useGetWarningDialog,
	useIsWarningDialogOpen,
} from '../providers/IssueCreateWarningDialogContextProvider';

import { useCreateWarningDialogNav } from './useCreateWarningDialogNav';
import { useGetWarningDialogRetry } from './useGetWarningDialogRetry';

export const useGetWarningDialogActions = (onClose: () => void) => {
	const { handleConfirm: navConfirm, handleCancel: navCancel } = useCreateWarningDialogNav(onClose);
	const { handleConfirm: retryConfirm, handleCancel: retryCancel } = useGetWarningDialogRetry();

	const isWarningDialogOpen = useIsWarningDialogOpen();
	const warningDialog = useGetWarningDialog();

	const handleConfirm = warningDialog === WarningDialog.FLOATING ? retryConfirm : navConfirm;
	const handleCancel = warningDialog === WarningDialog.FLOATING ? retryCancel : navCancel;

	return {
		isWarningDialogOpen,
		handleConfirm,
		handleCancel,
	};
};
