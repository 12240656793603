import React from 'react';

import Avatar from '@atlaskit/avatar';
import { Inline, Text } from '@atlaskit/primitives';

type BulkConfigureSelectOptionProps = {
	label: string;
	avatarUrl: string;
};

export const BulkConfigureSelectOption = ({ label, avatarUrl }: BulkConfigureSelectOptionProps) => {
	return (
		<Inline space="space.100" alignBlock="center">
			<Avatar appearance="square" size="xsmall" src={avatarUrl} />
			<Text size="medium">{label}</Text>
		</Inline>
	);
};
