import { useEffect } from 'react';

import {
	useAvailableSitesState,
	useIssueTypesState,
	useProjectsState,
} from '../providers/BulkConfigureContextProvider';
import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';

import { useGetAvailableSites } from './useGetAvailableSites';
import { useGetJiraProjects } from './useGetJiraProjects';
import { useGetIssueTypes } from './useGetIssueTypes';

export const useGetInitialData = () => {
	useGetAvailableSites();
	const { selectedAvailableSite } = useAvailableSitesState();
	const [{ currentJiraSite }, { setCurrentJiraSite }] = useBulkCreateContext();
	useEffect(() => {
		if (selectedAvailableSite && !currentJiraSite) {
			void setCurrentJiraSite(selectedAvailableSite);
		}
	}, [selectedAvailableSite, currentJiraSite, setCurrentJiraSite]);

	useGetJiraProjects();
	const { selectedProject } = useProjectsState();
	const [{ currentJiraProject }, { setCurrentJiraProject }] = useBulkCreateContext();
	useEffect(() => {
		if (selectedProject && !currentJiraProject) {
			void setCurrentJiraProject(selectedProject);
		}
	}, [selectedProject, currentJiraProject, setCurrentJiraProject]);

	useGetIssueTypes();
	const { selectedIssueType } = useIssueTypesState();
	const [{ currentDefaultIssueType }, { setCurrentDefaultIssueType }] = useBulkCreateContext();
	useEffect(() => {
		if (selectedIssueType && !currentDefaultIssueType) {
			void setCurrentDefaultIssueType(selectedIssueType);
		}
	}, [selectedIssueType, currentDefaultIssueType, setCurrentDefaultIssueType]);
};
