import { cfetch } from '@confluence/network';

import type { InsertStorageFragmentRequestPayload } from '../__types__/InsertStorageFragmentRequestPayload';
import type { InsertStorageColumnTableRequestPayload } from '../__types__/InsertStorageColumnTableRequestPayload';

const REFRESH_TIMEOUT = 3000;

export const constructJiraIssueUrl = (
	jiraSiteUrl: string | undefined,
	issueKey: string,
): string => {
	return `${jiraSiteUrl}/browse/${issueKey}`;
};

export const constructSmartlinkTag = (url: string) => {
	return `<a href="${url}" data-card-appearance="inline">${url}</a>`;
};

export const bulkConstructSmartlinkTags = (urls: string[]): string => {
	return urls.map((url) => constructSmartlinkTag(url)).join(' ');
};

export const constructHeading = (text: string): string => {
	return `<h2>${text}</h2>`;
};

const getLastFetchTimeFromMetaTag = (): number | null => {
	const metaTag = document.querySelector(`meta[name="confluence-request-time"]`);
	return metaTag ? Number(metaTag.getAttribute('content')) : null;
};

const constructBasePayload = (
	contentId: string,
	result: {
		searchText: {
			numMatches: number;
			index: number;
			selectedText: string;
		};
	},
	lastModified?: string | null,
) => {
	// This happens when the page is completely empty.
	if (!result || !result.searchText || result.searchText.numMatches === undefined) {
		return {
			pageId: contentId,
			selectedText: '',
			index: -1,
			numMatches: 0,
			lastFetchTime: lastModified
				? new Date(lastModified).getTime()
				: getLastFetchTimeFromMetaTag(),
		};
	}

	const {
		searchText: { numMatches, index: matchIndex, selectedText },
	} = result;

	return {
		pageId: contentId,
		selectedText,
		index: matchIndex,
		numMatches,
		// Get last fetch time of current page.
		// This used to be the request time, but this caused problems due to time drift between the server and database.
		// So now try to use the content last modification date on load if that exists, then fallback onto the
		// request time if it's not available.
		lastFetchTime: lastModified ? new Date(lastModified).getTime() : getLastFetchTimeFromMetaTag(),
	};
};

export const constructInsertStorageFragmentRequestPayload = (
	contentId: string,
	convertSelectionToLegacyFormat: (
		contentRef: HTMLElement | null | undefined,
		range: Range | undefined,
		contentId: string,
	) => {
		searchText: {
			numMatches: number;
			index: number;
			selectedText: string;
		};
	},
	range?: Range,
	contentRef?: HTMLElement | null,
	lastModified?: string | null,
) => {
	const result = convertSelectionToLegacyFormat(contentRef, range, contentId);
	return {
		...constructBasePayload(contentId, result, lastModified),
		xmlModification: undefined,
	} as InsertStorageFragmentRequestPayload;
};

export const constructInsertStorageColumnTableRequestPayload = (
	contentId: string,
	convertSelectionToLegacyFormat: (
		contentRef: HTMLElement | null | undefined,
		range: Range | undefined,
		contentId: string,
	) => {
		searchText: {
			numMatches: number;
			index: number;
			selectedText: string;
		};
	},
	range?: Range,
	contentRef?: HTMLElement | null,
	lastModified?: string | null,
	tableColumnIndex?: number | null,
) => {
	const result = convertSelectionToLegacyFormat(contentRef, range, contentId);
	return {
		...constructBasePayload(contentId, result, lastModified),
		tableColumnIndex: tableColumnIndex ?? 0,
		cellModifications: [],
	} as InsertStorageColumnTableRequestPayload;
};

export const insertContentAtSelectionEnd = async (
	insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload,
) => {
	try {
		const restUrl = '/wiki/rest/highlighting/1.0/insert-storage-fragment';

		const response = await cfetch(restUrl, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(insertStorageFragmentRequestPayload),
		});

		if (!response.ok) {
			throw new Error('Insert storage fragment request failed');
		}

		return await response.json();
	} catch (error) {
		throw error;
	}
};

export const insertContentAtColumn = async (
	insertStorageColumnTableRequestPayload: InsertStorageColumnTableRequestPayload,
) => {
	const restUrl = '/wiki/rest/highlighting/1.0/insert-storage-column-table';

	const response = await cfetch(restUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(insertStorageColumnTableRequestPayload),
	});
	if (!response.ok) {
		throw new Error('Insert storage column table request failed');
	}

	return response.json();
};

export const refreshPageWithTimeout = () => {
	setTimeout(() => {
		window.location.reload();
	}, REFRESH_TIMEOUT);
};

export const getValidRange = (originalRange: Range | undefined | null): Range | undefined => {
	if (!originalRange) return undefined;

	const newRange = document.createRange();
	let lastTextNode: Text | null = null;
	let lastTextNodeStartOffset: number = 0;
	let lastTextNodeEndOffset: number = 0;

	const INVALID_NODE_SELECTORS = [
		// Status macro
		'span[data-node-type="status"]',
		// Inline Smart Card/Jira issue link
		'[data-inline-card="true"]',
		'.inlineCardView-content-wrap',
		'.smart-link-title-wrapper',
	].join(',');

	const isInvalidNode = (node: Node): boolean => {
		return node.nodeType === Node.ELEMENT_NODE && (node as Element).matches(INVALID_NODE_SELECTORS);
	};

	const hasInvalidAncestor = (node: Node, ancestorLimit: Node): boolean => {
		let currentNode: Node | null = node;
		while (currentNode && currentNode !== ancestorLimit) {
			if (isInvalidNode(currentNode)) {
				return true;
			}
			currentNode = currentNode.parentNode;
		}
		return false;
	};

	const previousNode = (node: Node, range: Range): Node | null => {
		if (node.previousSibling) {
			node = node.previousSibling;
			while (node.hasChildNodes() && !isInvalidNode(node)) {
				node = node.lastChild!;
			}
			return node;
		}
		return node.parentNode && node.parentNode !== range.commonAncestorContainer
			? node.parentNode
			: null;
	};

	let currentNode: Node | null = originalRange.endContainer;
	while (currentNode) {
		// If the current node is a text node and it is not empty, and it does not have an invalid ancestor, then it is a valid node.
		if (
			currentNode.nodeType === Node.TEXT_NODE &&
			currentNode.textContent &&
			currentNode.textContent.trim() !== '' &&
			!hasInvalidAncestor(currentNode, originalRange.commonAncestorContainer)
		) {
			lastTextNode = currentNode as Text;
			if (originalRange.endContainer === currentNode) {
				lastTextNodeEndOffset = originalRange.endOffset;
			} else {
				lastTextNodeEndOffset = currentNode.textContent.length;
			}
			if (originalRange.startContainer === currentNode) {
				lastTextNodeStartOffset = originalRange.startOffset;
			} else {
				lastTextNodeStartOffset = 0;
			}
			break; // Early termination as soon as we find the last valid text node.
		}
		// Terminate when we reach the start of the original range.
		if (currentNode === originalRange.startContainer) break;
		currentNode = previousNode(currentNode, originalRange);
	}

	if (lastTextNode) {
		newRange.setStart(lastTextNode, lastTextNodeStartOffset);
		newRange.setEnd(lastTextNode, lastTextNodeEndOffset);
		return newRange;
	}
	return originalRange;
};

export const getPageContentRange = (): Range | undefined => {
	const contentElement = document.getElementById('main-content');
	if (contentElement) {
		const range = document.createRange();
		range.selectNodeContents(contentElement);

		const findDeepestChild = (node: Node): Node => {
			let current = node;
			while (current.hasChildNodes()) {
				current = current.lastChild!;
			}
			return current;
		};

		const endContainer = findDeepestChild(range.endContainer);
		let endOffset = 0;
		if (endContainer.nodeType === Node.TEXT_NODE) {
			endOffset = endContainer.textContent?.length || 0;
		} else if (endContainer.nodeType === Node.ELEMENT_NODE) {
			endOffset = (endContainer as Element).childNodes.length;
		}
		range.setEnd(endContainer, endOffset);

		return range;
	}
	return undefined;
};
