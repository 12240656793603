import { parse } from 'partial-json';

import type { AIAgentStreamingConfig } from '../../models/AIAgentStreamingConfig';
import {
	AIAgentResponseState,
	type AIAgentStreamingState,
} from '../../models/AIAgentStreamingState';

export function parsePartialJsonIfNeeded<TAIAgentContentResponse>(
	state: AIAgentStreamingState,
	config: AIAgentStreamingConfig,
): AIAgentStreamingState<TAIAgentContentResponse> {
	if (
		state.responseState !== AIAgentResponseState.Initialization &&
		config.enablePartialJson &&
		state.content
	) {
		try {
			const parsedContent = parse(state.content);
			return {
				...state,
				content: parsedContent,
			};
		} catch (error) {}
	}
	return state as AIAgentStreamingState<TAIAgentContentResponse>;
}
