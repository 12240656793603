import { useCallback } from 'react';

import type { AdditionalIssueField } from '../providers/BulkCreateContextProvider';
import { useBulkCreateContext, useGetIssueType } from '../providers/BulkCreateContextProvider';
import type { CreateAllIssueResponse, CreateIssueBodyFields } from '../__types__/apiUtils';

import { useCreateBulkIssues } from './useBulkCreateIssues';

export const useCreateAllIssues = () => {
	const [{ bulkIssuesList, currentJiraProject }] = useBulkCreateContext();
	const { createBulkIssuesHandler, loading, response } = useCreateBulkIssues();
	const issueType = useGetIssueType();

	const createAllIssuesHandler = useCallback(
		async ({
			onComplete,
			onFailure,
		}: {
			onComplete: (response: CreateAllIssueResponse) => void;
			onFailure: (error: Error | unknown) => void;
		}) => {
			if (!currentJiraProject || bulkIssuesList.length === 0) {
				return;
			}

			const issueUpdates = bulkIssuesList.map((issue) => {
				const fields: CreateIssueBodyFields = {
					description: issue.description,
					issuetype: { id: issueType.id },
					project: { id: currentJiraProject.id },
					summary: issue.summary,
				};

				if (issue.additionalFields) {
					issue.additionalFields.forEach((f: AdditionalIssueField) => {
						fields[f.field.fieldId] = f.value;
					});
				}
				return { fields };
			});

			const rowIndices = bulkIssuesList
				.filter((issue) => issue.rowIndex !== undefined)
				.map((issue) => Number(issue.rowIndex));

			void createBulkIssuesHandler({
				issueBody: {
					issueUpdates,
				},
				onComplete: ({ issues: createdIssues, errors: failedIssuesResponse }) => {
					const failedIssueIDs = failedIssuesResponse.map(
						(errorItem) => bulkIssuesList?.[errorItem.failedElementNumber]?.id,
					);
					const createdIssueIDs = bulkIssuesList
						.filter((issue) => !failedIssueIDs.includes(issue.id))
						.map((issue) => issue.id);
					onComplete({ createdIssueIDs, createdIssues, failedIssueIDs });
				},
				onFailure,
				rowIndices,
			});
		},
		[currentJiraProject, bulkIssuesList, createBulkIssuesHandler, issueType.id],
	);

	return {
		createAllIssuesHandler,
		loading,
		response,
	};
};
