import React from 'react';

import { BulkCreateContainer } from './BulkCreateContextProvider';
import { AiBulkNavigationContextContainer } from './AiBulkNavigationContextProvider';
import { BulkConfigureContextContainer } from './BulkConfigureContextProvider';

type BulkIssueCreateProvidersWrapperProps = {
	children?: React.ReactNode;
};
export const BulkIssueCreateProvidersWrapper: React.FC<BulkIssueCreateProvidersWrapperProps> = ({
	children,
}) => {
	return (
		<BulkCreateContainer isGlobal>
			<AiBulkNavigationContextContainer>
				<BulkConfigureContextContainer>{children}</BulkConfigureContextContainer>
			</AiBulkNavigationContextContainer>
		</BulkCreateContainer>
	);
};
