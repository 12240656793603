import { useCallback } from 'react';
import { useIntl } from 'react-intl-next';

import { useRouteDataRef } from '@confluence/route-manager';
import { fg } from '@confluence/feature-gating';

import type { CreateIssueResponse } from '../__types__/apiUtils';
import { messages } from '../messages';
import {
	useBulkCreateContext,
	useResetSingleIssuesCreated,
} from '../providers/BulkCreateContextProvider';
import type { InsertStorageFragmentRequestPayload } from '../__types__/InsertStorageFragmentRequestPayload';
import type {
	CellModification,
	InsertStorageColumnTableRequestPayload,
} from '../__types__/InsertStorageColumnTableRequestPayload';
import { useBulkCreateLoggingEvents } from '../utils/useBulkCreateLoggingEvents';
import { useIssueCreateSidePanelContext } from '../providers/IssueCreateSidePanelContextProvider';

import {
	bulkConstructSmartlinkTags,
	constructSmartlinkTag,
	constructHeading,
	constructJiraIssueUrl,
	insertContentAtSelectionEnd,
	insertContentAtColumn,
} from './issueInsertionUtils';
import { useBulkCreateFlags } from './useBulkCreateFlags';

const JIRA_SINGLE_ISSUE_LINK_INSERTION = 'jiraSingleIssueLinkInsertion';
const JIRA_MULTIPLE_ISSUE_LINK_INSERTION = 'jiraMultipleIssueLinkInsertion';

export const useGetIssueInsertionActions = () => {
	const { formatMessage } = useIntl();
	const [
		{ currentJiraSite, insertStorageFragmentRequestPayload, singleIssuesCreated, isCreatedWithAi },
	] = useBulkCreateContext();
	const [{ insertStorageColumnTableRequestPayload }] = useIssueCreateSidePanelContext();
	const {
		triggerSingleIssueInsertLinkFailureFlag,
		triggerMultipleIssuesInsertLinkFailureFlag,
		triggerMultipleIssuesCreatedFlag,
	} = useBulkCreateFlags();
	const resetSingleIssuesCreated = useResetSingleIssuesCreated();
	const { logRequestFailed } = useBulkCreateLoggingEvents();
	const headingString = formatMessage(
		fg('confluence-issue-terminology-refresh')
			? messages.bulkCreateIssueInsertionHeadingIssueTermRefresh
			: messages.bulkCreateIssueInsertionHeading,
	);
	const currentUrl = useRouteDataRef().current?.href;

	const constructXmlModification = useCallback(
		(
			createdIssues: CreateIssueResponse[],
			insertStorageFragmentRequestPayload: InsertStorageFragmentRequestPayload | undefined,
		) => {
			const smartlinkTags = bulkConstructSmartlinkTags(
				createdIssues.map((issue) => constructJiraIssueUrl(currentJiraSite?.url, issue.key)),
			);
			return insertStorageFragmentRequestPayload?.selectedText !== headingString
				? `${constructHeading(headingString)}<br/>${smartlinkTags}`
				: `<p>${smartlinkTags}</p>`;
		},
		[currentJiraSite?.url, headingString],
	);

	const constructCellModifications = useCallback(
		(createdIssues: CreateIssueResponse[]): CellModification[] | undefined => {
			return createdIssues
				.filter((issue) => issue.rowIndex !== undefined)
				.map((issue) => {
					const xmlInsertion = constructSmartlinkTag(
						constructJiraIssueUrl(currentJiraSite?.url, issue.key),
					);
					return { rowIndex: Number(issue.rowIndex), xmlInsertion };
				});
		},
		[currentJiraSite?.url],
	);

	const constructAnchorLinkToHeading = useCallback(() => {
		const formattedHeading = headingString.split(' ').join('-');
		const [baseUrl, existingHash] = (currentUrl ?? '').split('#');
		return existingHash ? `${baseUrl}#${formattedHeading}` : `${currentUrl}#${formattedHeading}`;
	}, [currentUrl, headingString]);

	const reloadOnClick = useCallback(() => {
		// eslint-disable-next-line no-restricted-syntax
		window.location.href = constructAnchorLinkToHeading();
		window.location.reload();
	}, [constructAnchorLinkToHeading]);

	const handleStorageInsertion = useCallback(
		(
			issuesCreated: CreateIssueResponse[],
			payload: InsertStorageFragmentRequestPayload | InsertStorageColumnTableRequestPayload,
		) => {
			let showReloadAction = false;
			const insertPromise = isCreatedWithAi
				? insertContentAtSelectionEnd(payload as InsertStorageFragmentRequestPayload)
				: insertContentAtColumn(payload as InsertStorageColumnTableRequestPayload);
			insertPromise
				.then((data) => {
					// There is a case where the API response is OK, but the response data is false.
					// When this happens, the backend has trouble inserting the ticket to the page (likely due to a payload error) and we should show an error toast.
					if (data === false) {
						throw new Error('Failed to insert storage');
					}
				})
				.then(() => {
					showReloadAction = true;
					void resetSingleIssuesCreated();
				})
				.catch((_error) => {
					if (issuesCreated.length === 1) {
						triggerSingleIssueInsertLinkFailureFlag({
							issueKey: issuesCreated[0].key,
							summary: issuesCreated[0].summary,
						});
						logRequestFailed({
							requestName: JIRA_SINGLE_ISSUE_LINK_INSERTION,
							errorMessage: (_error as Error).message,
						});
					} else {
						triggerMultipleIssuesInsertLinkFailureFlag(issuesCreated);
						logRequestFailed({
							requestName: JIRA_MULTIPLE_ISSUE_LINK_INSERTION,
							errorMessage: (_error as Error).message,
						});
					}
				})
				.finally(() => {
					triggerMultipleIssuesCreatedFlag(issuesCreated, reloadOnClick, showReloadAction);
				});
		},
		[
			isCreatedWithAi,
			reloadOnClick,
			triggerMultipleIssuesCreatedFlag,
			triggerMultipleIssuesInsertLinkFailureFlag,
			triggerSingleIssueInsertLinkFailureFlag,
			logRequestFailed,
			resetSingleIssuesCreated,
		],
	);

	const addSingleIssuesToPage = useCallback(() => {
		if (insertStorageFragmentRequestPayload && isCreatedWithAi && singleIssuesCreated.length > 0) {
			insertStorageFragmentRequestPayload.xmlModification = constructXmlModification(
				singleIssuesCreated,
				insertStorageFragmentRequestPayload,
			);
			handleStorageInsertion(singleIssuesCreated, insertStorageFragmentRequestPayload);
		} else if (
			insertStorageColumnTableRequestPayload &&
			!isCreatedWithAi &&
			singleIssuesCreated.length > 0
		) {
			insertStorageColumnTableRequestPayload.cellModifications =
				constructCellModifications(singleIssuesCreated);
			handleStorageInsertion(singleIssuesCreated, insertStorageColumnTableRequestPayload);
		}
	}, [
		constructXmlModification,
		constructCellModifications,
		insertStorageFragmentRequestPayload,
		insertStorageColumnTableRequestPayload,
		singleIssuesCreated,
		handleStorageInsertion,
		isCreatedWithAi,
	]);

	return {
		constructAnchorLinkToHeading,
		constructXmlModification,
		constructCellModifications,
		addSingleIssuesToPage,
	};
};
