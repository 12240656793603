import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Inline, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';

import { fg } from '@confluence/feature-gating';

import { useSetErrorMessage } from './providers/AiBulkNavigationContextProvider';
import { useCreateAllIssues } from './utils/useCreateAllIssues';
import { useBulkCreateContext } from './providers/BulkCreateContextProvider';
import { useBulkCreateFlags } from './utils/useBulkCreateFlags';
import { useBulkCreateLoggingEvents } from './utils/useBulkCreateLoggingEvents';
import { messages } from './messages';
import { insertContentAtSelectionEnd, insertContentAtColumn } from './utils/issueInsertionUtils';
import { useGetIssueInsertionActions } from './utils/useGetIssueInsertionActions';
import { useIssueCreateSidePanelContext } from './providers/IssueCreateSidePanelContextProvider';
import type { CreateIssueResponse } from './__types__/apiUtils';
import type { InsertStorageFragmentRequestPayload } from './__types__/InsertStorageFragmentRequestPayload';
import type { InsertStorageColumnTableRequestPayload } from './__types__/InsertStorageColumnTableRequestPayload';

const buttonGroupStyles = xcss({
	paddingTop: 'space.050',
});

// Bulk create button group - used for both AI and non-AI bulk create
export const BulkCreateButtonGroup = ({
	onClose,
	onCreateAll,
}: {
	onClose: () => void;
	onCreateAll: () => void;
}) => {
	const { createAllIssuesHandler, loading: isCreateAllLoading } = useCreateAllIssues();
	const [
		{
			bulkIssuesList,
			issueTypeHasRequiredFields,
			insertStorageFragmentRequestPayload,
			singleIssuesCreated,
			isCreatedWithAi,
			isCreatedFromTable,
		},
		{ removeBulkIssueList },
	] = useBulkCreateContext();
	const [{ insertStorageColumnTableRequestPayload }] = useIssueCreateSidePanelContext();
	const {
		triggerMultipleIssuesCreatedFlag,
		triggerSingleIssueInsertLinkFailureFlag,
		triggerMultipleIssuesInsertLinkFailureFlag,
	} = useBulkCreateFlags();
	const setErrorMessage = useSetErrorMessage();
	const { logCreateAllClick, logMultipleIssueCreateSuccess, logIssueCreationFailed } =
		useBulkCreateLoggingEvents();
	const { constructAnchorLinkToHeading, constructXmlModification, constructCellModifications } =
		useGetIssueInsertionActions();

	const reloadOnClick = useCallback(() => {
		// eslint-disable-next-line no-restricted-syntax
		window.location.href = constructAnchorLinkToHeading();
		window.location.reload();
	}, [constructAnchorLinkToHeading]);

	const isCreateAllDisabled =
		bulkIssuesList.length === 0 ||
		issueTypeHasRequiredFields ||
		(isCreatedFromTable &&
			bulkIssuesList.length > 50 &&
			fg('confluence_bulk_issue_creation_exceed_limit_text'));

	const handleStorageInsertion = useCallback(
		(
			createdIssues: CreateIssueResponse[],
			payload: InsertStorageFragmentRequestPayload | InsertStorageColumnTableRequestPayload,
		) => {
			let showReloadAction = false;
			const insertPromise = isCreatedWithAi
				? insertContentAtSelectionEnd(payload as InsertStorageFragmentRequestPayload)
				: insertContentAtColumn(payload as InsertStorageColumnTableRequestPayload);
			insertPromise
				.then((data) => {
					// There is a case where the API response is OK, but the response data is false.
					// When this happens, the backend has trouble inserting the ticket to the page (likely due to a payload error) and we should show an error toast.
					if (data === false) {
						throw new Error('Failed to insert storage');
					}
				})
				.then(() => {
					showReloadAction = true;
				})
				.catch((_error) => {
					if (createdIssues.length === 1) {
						triggerSingleIssueInsertLinkFailureFlag({
							issueKey: createdIssues[0].key,
							summary: createdIssues[0].summary,
						});
					} else {
						triggerMultipleIssuesInsertLinkFailureFlag(createdIssues);
					}
				})
				.finally(() => {
					triggerMultipleIssuesCreatedFlag(createdIssues, reloadOnClick, showReloadAction);
				});
		},
		[
			isCreatedWithAi,
			reloadOnClick,
			triggerMultipleIssuesCreatedFlag,
			triggerMultipleIssuesInsertLinkFailureFlag,
			triggerSingleIssueInsertLinkFailureFlag,
		],
	);

	const onCreateAllClick = useCallback(() => {
		logCreateAllClick();
		setErrorMessage(null);
		void createAllIssuesHandler({
			onComplete: ({ createdIssues, createdIssueIDs, failedIssueIDs }) => {
				logMultipleIssueCreateSuccess(createdIssueIDs.length);
				if (isCreatedWithAi && insertStorageFragmentRequestPayload) {
					insertStorageFragmentRequestPayload.xmlModification = constructXmlModification(
						[...createdIssues, ...singleIssuesCreated],
						insertStorageFragmentRequestPayload,
					);
					handleStorageInsertion(createdIssues, insertStorageFragmentRequestPayload);
				} else if (!isCreatedWithAi && insertStorageColumnTableRequestPayload) {
					insertStorageColumnTableRequestPayload.cellModifications = constructCellModifications([
						...createdIssues,
						...singleIssuesCreated,
					]);
					handleStorageInsertion(
						[...createdIssues, ...singleIssuesCreated],
						insertStorageColumnTableRequestPayload,
					);
				} else {
					triggerMultipleIssuesCreatedFlag(createdIssues, reloadOnClick, false);
				}
				void removeBulkIssueList(createdIssueIDs);
				if (failedIssueIDs.length !== 0) {
					logIssueCreationFailed(failedIssueIDs.length);
					void setErrorMessage({
						createdCount: createdIssueIDs.length,
						failedCount: failedIssueIDs.length,
					});
				} else {
					onCreateAll();
				}
			},
			onFailure: (error: Error | unknown) => {
				logIssueCreationFailed(bulkIssuesList.length, error);
				void setErrorMessage({ createdCount: 0, failedCount: bulkIssuesList.length });
			},
		});
	}, [
		logCreateAllClick,
		setErrorMessage,
		createAllIssuesHandler,
		logMultipleIssueCreateSuccess,
		insertStorageFragmentRequestPayload,
		insertStorageColumnTableRequestPayload,
		removeBulkIssueList,
		constructXmlModification,
		constructCellModifications,
		singleIssuesCreated,
		triggerMultipleIssuesCreatedFlag,
		reloadOnClick,
		logIssueCreationFailed,
		onCreateAll,
		bulkIssuesList,
		isCreatedWithAi,
		handleStorageInsertion,
	]);

	return (
		<Inline space="space.150" xcss={buttonGroupStyles}>
			<Button appearance="subtle" onClick={onClose} testId="bulk-create-button-group-cancel">
				<FormattedMessage {...messages.bulkCreateCancelFooterButton} />
			</Button>
			<Button
				appearance="primary"
				isDisabled={isCreateAllDisabled}
				isLoading={isCreateAllLoading}
				onClick={onCreateAllClick}
				testId="bulk-create-button-group-create-all"
			>
				<FormattedMessage {...messages.bulkCreateCreateAllFooterButton} />
			</Button>
		</Inline>
	);
};
