import React, { useCallback } from 'react';

import { Stack, xcss } from '@atlaskit/primitives';

import { BulkCreateBody } from './BulkCreateBody';
import { BulkCreateHeader } from './BulkCreateHeader';
import { BulkCreateFooter } from './BulkCreateFooter';
import { SidePanelWrapper } from './SidePanelWrapper';
import {
	BULK_ISSUE_CREATE_SOURCE,
	useBulkCreateLoggingEvents,
} from './utils/useBulkCreateLoggingEvents';
import {
	useBulkCreateContext,
	ErrorMessageMap,
	ErrorStates,
	AiStates,
} from './providers/BulkCreateContextProvider';
import { useGetIssueInsertionActions } from './utils/useGetIssueInsertionActions';
import { AiCreationError } from './AiCreationError';
import { IssueCreateAiLoading } from './IssueCreateAiLoading';
import { RainbowLoadingSidebar } from './RainbowLoadingSidebar';

const contentStyles = xcss({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	flexWrap: 'nowrap',
});

export const AiBulkDefaultView = ({ onClose }: { onClose: () => void }) => {
	const { logCancelAiPanel } = useBulkCreateLoggingEvents();
	const { addSingleIssuesToPage } = useGetIssueInsertionActions();
	const [{ aiState, error }, actions] = useBulkCreateContext();

	const onPanelClose = useCallback(() => {
		logCancelAiPanel();
		addSingleIssuesToPage();
		void actions.resetAIBulkCreateState();
		onClose();
	}, [logCancelAiPanel, onClose, addSingleIssuesToPage, actions]);

	const isLoading = aiState === AiStates.LOADING;
	const isError = error !== ErrorStates.EMPTY;
	const isComplete = !isLoading && !isError;

	return (
		<SidePanelWrapper>
			<RainbowLoadingSidebar isShown={isLoading}>
				<Stack xcss={contentStyles}>
					<BulkCreateHeader onClose={onPanelClose} />
					{isLoading && <IssueCreateAiLoading loadingType="bulk" />}
					{isError && (
						<AiCreationError
							creationSource={BULK_ISSUE_CREATE_SOURCE}
							errorMessage={ErrorMessageMap[error]}
						/>
					)}
					{isComplete && (
						<>
							<BulkCreateBody />
							<BulkCreateFooter onClose={onPanelClose} onCreateAll={onClose} />
						</>
					)}
				</Stack>
			</RainbowLoadingSidebar>
		</SidePanelWrapper>
	);
};
