import { useEffect } from 'react';

import { AiStates, useBulkCreateContext } from '../providers/BulkCreateContextProvider';
import { useIsConfigureLoading } from '../providers/BulkConfigureContextProvider';

import { useAIAgentStreamingData } from './useAIAgentStreamingData';

export const useAiBulkFetchIssuesList = () => {
	const [, { setAiState }] = useBulkCreateContext();
	const { currentAiState } = useAIAgentStreamingData();
	const { isLoading } = useIsConfigureLoading();

	useEffect(() => {
		if (currentAiState === AiStates.IDLE && isLoading) {
			return;
		}
		void setAiState(currentAiState);
	}, [currentAiState, setAiState, isLoading]);
};
